import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { 
    BlockTypeSelect,
    BoldItalicUnderlineToggles, 
    CodeToggle, 
    ListsToggle, 
    MDXEditor, 
    UndoRedo, 
    headingsPlugin, 
    listsPlugin, 
    quotePlugin, 
    toolbarPlugin 
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';

const MarkdownEditor = ({
    disabled,
    onBlur,
    onChange,
    value
}) => {
    const editorRef =  useRef(null);
    const [timer, setTimer] = useState(null);

    useEffect(
        () => editorRef.current?.setMarkdown(value || ''), 
        [value]
    );

    useEffect(() => {
        return () => timer && clearTimeout(timer);
    }, []);

    const onBlurInner = () => {
        timer && clearTimeout(timer);
        onBlur?.();
    };

    const onChangeInner = (value) => {
        timer && clearTimeout(timer);
        onChange?.(value);
        setTimer(setTimeout(onBlurInner, 2000));
    };

    const getToolbarContent = () => (
        <>
            {' '}
            <UndoRedo />
            <BoldItalicUnderlineToggles />
            <BlockTypeSelect />
            <ListsToggle />
            <CodeToggle />
        </>
    );

    return (
        <MDXEditor 
            ref={editorRef}
            markdown=""
            style={{maxHeight: 100}}
            disabled={disabled}
            plugins={[
                toolbarPlugin({
                    toolbarContents: getToolbarContent
                }),
                headingsPlugin({ allowedHeadingLevels: [1, 2, 3] }),
                listsPlugin(),
                quotePlugin(),
            ]}
            onChange={onChangeInner}
            onBlur={onBlurInner}
        />
    );
};

MarkdownEditor.propTypes = {
    disabled: PropTypes.bool,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string
};

export default MarkdownEditor;