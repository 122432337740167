import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { ADVENTURE_POINT_INFO_HASH, ADVENTURE_POINT_NOTES_HASH, ADVENTURE_POINTS_HASH } from "../../../router/links";
import { loadAdventurePoint, updateAdventurePointField } from "../../../api/adventurePoints";
import { Container, Grid, Icon, Tab } from "semantic-ui-react";
import AutoSaveInput from "../../../controls/autoSaveInput";
import AdventurePointNotesTab from "../tabs/point/adventurePointNotesTab";
import AdventurePointInfoTab from "../tabs/point/adventuvePointInfoTab";
import AdventureHeader from "../components/adventureHeader";

const AdventurePointPage = () => {
    const { id } = useParams();
    const { hash } = useLocation();
    
    const [form, setForm] = useState({});
    const [name, setName] = useState('');
    const [activeTab, setActiveTab] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const getData = () => {
        setIsLoading(true);
        loadAdventurePoint(id)
            .then(result => {
                setIsLoading(false);

                setForm(result);
                setName(result.name || '');
                
                document.title = `Overdice - ${result.adventureName || 'приключения'} - ${result.name || 'места'}`;
            })
            .catch(() => setIsLoading(false));
    }
    useEffect(getData, [id]);

    const saveField = (fieldName, value) => {
        setIsLoading(true);
        updateAdventurePointField(id, fieldName, value)
            .then(() => setIsLoading(false))
            .catch(() => setIsLoading(false));
    };

    const panes = [
        {
            tag: ADVENTURE_POINT_INFO_HASH,
            render: () => (
                <AdventurePointInfoTab
                    key={'adventure-point-info-' + (form.id || 'none')}
                    disabled={isLoading}
                    point={form} 
                    onSaveField={saveField}
                />
            )
        },
        {
            tag: ADVENTURE_POINT_NOTES_HASH,
            render: () => (
                <AdventurePointNotesTab
                    key={'adventure-point-notes-' + (form.id || 'none')}
                    disabled={isLoading}
                    adventureId={form.adventureId} 
                    pointId={form.id} 
                />
            )
        }
    ];

    const syncActiveTab = () => {
        if (hash) {
            panes.forEach((tab, index) => {
                if (tab.tag === hash) {
                    setActiveTab(index);
                }
            });
        } else {
            setActiveTab(0);
        }
    };
    useEffect(syncActiveTab, [hash]);

    const getMenu = () => <></>;

    return (
        <Container>
            <AdventureHeader
                adventureId={form.adventureId}
                adventureName={form.adventureName}
                adventurePageTag={ADVENTURE_POINTS_HASH}
            />
            <Grid>
                <Grid.Row className='adventure-sub-header adventure-point-header'>
                    <div className='header-main'>
                        <div className='header-title'>
                            <AutoSaveInput 
                                transparent 
                                size='big'
                                icon='list ol'
                                iconPosition='left'
                                className='title-input'
                                disabled={isLoading}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                onBlur={() => saveField('name', name)}
                            />
                        </div>
                    </div>
                    <div className='header-links'>
                        <Link 
                            title="Описание"
                            to={ADVENTURE_POINT_INFO_HASH} 
                            className={'ui basic circular icon tiny button' + (panes[activeTab].tag === ADVENTURE_POINT_INFO_HASH ? ' black' : '')}
                        >
                            <Icon name="info"/>
                        </Link>
                        <Link 
                            title="Заметки"
                            to={ADVENTURE_POINT_NOTES_HASH} 
                            className={'ui basic circular icon tiny button' + (panes[activeTab].tag === ADVENTURE_POINT_NOTES_HASH ? ' black' : '')}
                        >
                            <Icon name="sticky note outline"/>
                        </Link>
                    </div>
                </Grid.Row>
            </Grid>
            <Tab menu={{ as: getMenu }} panes={panes} activeIndex={activeTab} />
        </Container>
    );
};

export default AdventurePointPage;