import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Input, Segment } from "semantic-ui-react";

const FieldForm = ({ form, onChange, onDelete }) => {
    const handleFieldChange = (name, value) => {
        const newForm = {
            ...form,
            [name]: value
        };
        onChange?.(newForm);
    };

    return (
        <Segment>
            <Form>
                <Form.Field inline>
                    <label htmlFor="field-name-input">Название</label>
                    <Input 
                        id="field-name-input"
                        value={(form?.name) || ''}
                        onChange={(e, { value }) => handleFieldChange('name', value)}
                    />
                </Form.Field>
                <Button color="red" onClick={() => onDelete?.()}>
                    Удалить поле
                </Button>
            </Form>
        </Segment>
    );
};

FieldForm.propTypes = {
    form: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
};

export default FieldForm;