import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { Button, Grid, Header, Icon, Item, ItemContent, ItemDescription, ItemGroup, ItemHeader, ItemImage, Pagination } from "semantic-ui-react";
import CreateNamedEntryDialog from "../../../../modals/createNamedEntryDialog";
import ConfirmationDialog from "../../../../modals/confirmationDialog";
import { ADVENTURE_CHAPTER_PAGE_LINK } from "../../../../router/links";
import { createAdventureChapter, deleteAdventureChapter, reorderAdventureChapter, loadAdventureChaptersList } from "../../../../api/adventureChapters";

const AdventureChaptersTab = ({
    adventureId,
    disabled
}) => {
    const navigate = useNavigate();
    
    const [chapters, setChapters] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [confirmationDialog, setConfirmationDialog] = useState({ isOpen: false });
    const [isNewDialogOpen, setIsNewDialogOpen] = useState(false);
    const [newEntryErrors, setNewEntryErrors] = useState({});
    const [pageNumber, setPageNumber] = useState(1);
    const [pagesCount, setPagesCount] = useState(1);
    
    const reloadChapters = () => {
        if (adventureId) {
            setIsLoading(true);
            loadAdventureChaptersList(adventureId, pageNumber)
                .then(result => {
                    setIsLoading(false);
                    if (result) {
                        setChapters(result.entries);
                        setPageNumber(result.pageNumber || 1);
                        setPagesCount(result.pagesCount || 1);
                    }
                })
                .catch(() => setIsLoading(false));
        }
    };
    
    useEffect(reloadChapters, [adventureId, pageNumber]);

    const onAddNewChapter = (name) => {
        if (adventureId) {
            setIsLoading(true);
            setNewEntryErrors({});
            createAdventureChapter(adventureId, { name })
                .then((result) => {
                    setIsNewDialogOpen(false);
                    if (result?.id) {
                        navigate(ADVENTURE_CHAPTER_PAGE_LINK.replace(':id', result.id));
                    } else {
                        reloadChapters();
                        setIsLoading(false);
                    }
                })
                .catch((result) => {
                    setIsLoading(false);
                    setNewEntryErrors(result?.errors);
                });
        }
    };

    const handleDelete = (id, name) => {
        setConfirmationDialog({
            message: `Вы действительно хотите удалить главу '${name}'?`,
            isOpen: true,
            id
        });
    };

    const cancelDelete = () => {
        setConfirmationDialog({
            isOpen: false
        });
    };

    const confirmDelete = () => {
        const id = confirmationDialog.id;
        cancelDelete();
        setIsLoading(true);
        deleteAdventureChapter(id)
            .then(() => {
                reloadChapters();
                setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
    };

    const onDragStart = (ev, id) => ev.dataTransfer.setData("id", id);
    const onDragOver = ev => ev.preventDefault();

    const onDrop = (ev, newIndex) => {
        let id = ev.dataTransfer.getData("id");
        setIsLoading(true);
        reorderAdventureChapter(id, newIndex)
            .then(() => {
                reloadChapters();
                setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
    };

    const items = chapters.map((entry, ind) => (
        <Item 
            key={'person-' + ind}
            draggable
            className="draggable"
            onDragStart={e => onDragStart(e, entry.id)}
            onDragOver={e => onDragOver(e)}
            onDrop={e => onDrop(e, ind + 1)}
        >
            <ItemImage style={{ width: 25, cursor: 'pointer' }}>
                <Icon name="bars" size='large' />
            </ItemImage>
            <ItemContent>
                <ItemHeader>
                    <Link to={ADVENTURE_CHAPTER_PAGE_LINK.replace(':id', entry.id)}>
                        {entry.name}
                    </Link>
                    <Icon 
                        className="remove-button"
                        name="trash alternate" 
                        title='Удаление главы'
                        onClick={() => handleDelete(entry.id, entry.name)}
                    />
                </ItemHeader>
                <ItemDescription>{entry.shortDescription}</ItemDescription>
            </ItemContent>
        </Item>
    ));

    return (
        <Grid>
            <Grid.Row className="adventure-sub-header">
                <Grid.Column>
                    <Header size="medium" className="inline-title">
                        Главы
                    </Header>
                    <Button
                        circular
                        icon='plus'
                        size="tiny"
                        className="inline-title"
                        disabled={isLoading || disabled}
                        title="Создание новой"
                        onClick={() => {
                            setIsNewDialogOpen(true);
                            setNewEntryErrors({});
                        }}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    <ItemGroup divided>{items}</ItemGroup>
                </Grid.Column>
            </Grid.Row>
            {pagesCount > 1 ? (
                <Grid.Row>
                    <Grid.Column>
                        <Pagination 
                            defaultActivePage={pageNumber} 
                            totalPages={pagesCount} 
                            secondary
                            onPageChange={(e, { activePage }) => setPageNumber(activePage)}
                        />
                    </Grid.Column>
                </Grid.Row>
            ) : null}
            <CreateNamedEntryDialog
                isOpen={isNewDialogOpen}
                title='Создание новой главы'
                onCancel={() => setIsNewDialogOpen(false)}
                onSave={onAddNewChapter}
                errors={newEntryErrors}
            />
            <ConfirmationDialog
                isOpen={confirmationDialog.isOpen}
                title='Удаление главы'
                message={confirmationDialog.message}
                onCancel={cancelDelete}
                onSave={confirmDelete}
            />
        </Grid>
    );
};

AdventureChaptersTab.propTypes = {
    adventureId: PropTypes.string.isRequired,
    disabled: PropTypes.bool
};

export default AdventureChaptersTab;