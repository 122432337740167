import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Form, FormField, FormGroup, Grid, Icon, Input, Item, ItemContent, ItemGroup, ItemHeader, ItemImage, Message } from "semantic-ui-react";
import { adventureGlobalSearch } from "../../../../api/adventures";
import { Link } from "react-router-dom";
import { ADVENTURE_CHAPTER_PAGE_LINK, ADVENTURE_PERSON_PAGE_LINK, ADVENTURE_PLACE_PAGE_LINK, ADVENTURE_POINT_PAGE_LINK, PUBLIC_ADVENTURE_MATERIAL_PAGE } from "../../../../router/links";
import EditNoteDialog from "../../components/modals/editNoteDialog";
import { updateAdventureNote } from "../../../../api/adventureNotes";
import { loadAdventureChaptersList } from "../../../../api/adventureChapters";
import { loadAdventurePersonsList } from "../../../../api/adventurePersons";
import { loadAdventurePointsList } from "../../../../api/adventurePoints";
import { convertEntryToOption } from "../../../../utils/convertUtils.jsx";

const AdventureGlobalSearchTab = ({
    adventureId, 
    disabled
}) => {
    const [editDialog, setEditDialog] = useState({ isOpen: false });
    const [editNoteErrors, setEditNoteErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [text, setText] = useState('');
    const [entries, setEntries] = useState(null);
    const [chapters, setChapters] = useState([]);
    const [persons, setPersons] = useState([]);
    const [points, setPoints] = useState([]);
    
    const loadOptions = () => {
        if (adventureId) {
            loadAdventureChaptersList(adventureId).then(result => result && setChapters([{}].concat(result.entries.map(convertEntryToOption))));
            loadAdventurePersonsList(adventureId).then(result => result && setPersons([{}].concat(result.entries.map(convertEntryToOption))));
            loadAdventurePointsList(adventureId, null, null, null, null).then(result => setPoints([{}].concat(result.entries.map(convertEntryToOption))));
        }
    };
    useEffect(loadOptions, [adventureId]);

    const search = (actualText) => {
        setEntries(null);
        if (actualText?.length > 0) {
            setIsLoading(true);
            adventureGlobalSearch({ adventureId, text: actualText })
                .then(result => {
                    setIsLoading(false);
                    setEntries((result?.entries) || []);
                })
                .catch(() => setIsLoading(false));
        }
    };

    const editNote = (entry) => {
        setEditDialog({ entry, isOpen: true });
        setEditNoteErrors({});
    }
    
    const cancelEdit = () => setEditDialog({ isOpen: false });

    const confirmEdit = (data) => {
        setIsLoading(true);
        setEditNoteErrors({});
        updateAdventureNote(data.id, data)
            .then(() => {
                cancelEdit();
                search(text);
            })
            .catch((result) => {
                setIsLoading(false);
                setEditNoteErrors(result?.errors);
            });
    };

    const getIcon = (entry) => {
        if (entry.type === 'chapter') return 'list ol';
        else if (entry.type === 'material') return 'folder open outline';
        else if (entry.type === 'note') return entry.icon;
        else if (entry.type === 'person') return 'group';
        else if (entry.type === 'place') return 'map marker';
        else if (entry.type === 'point') return 'map signs';
        else return 'bars';
    };

    const getNoteHeader = (entry) => {
        return (
            <a onClick={() => editNote(entry.data)}>
                {entry.name}
            </a>
        );
    };

    const getMaterialHeader = (entry) => {
        return (
            <Link 
                to={PUBLIC_ADVENTURE_MATERIAL_PAGE.replace(':adventureId', adventureId).replace(':materialType', 'AdventureMaterial').replace(':materialId', entry.id)} 
                target="__blank"
            >
                {entry.name}
            </Link>
        );
    };

    const getEntryUrl = (entry) => {
        if (entry.type === 'chapter') return ADVENTURE_CHAPTER_PAGE_LINK.replace(':id', entry.id);
        else if (entry.type === 'person') return ADVENTURE_PERSON_PAGE_LINK.replace(':id', entry.id);
        else if (entry.type === 'place') return ADVENTURE_PLACE_PAGE_LINK.replace(':id', entry.id);
        else if (entry.type === 'point') return ADVENTURE_POINT_PAGE_LINK.replace(':id', entry.id);
        else return '';
    };

    const getBaseHeader = (entry) => {
        return (
            <Link to={getEntryUrl(entry)}>
                {entry.name}
            </Link>
        );
    };

    const getHeader = (entry) => {
        if (entry.type === 'note') return getNoteHeader(entry);
        else if (entry.type === 'material') return getMaterialHeader(entry);
        else return getBaseHeader(entry);
    };

    const items = entries?.map((entry, ind) => (
        <Item key={'search-' + ind}>
            <ItemImage style={{ width: 20 }}>
                <Icon name={getIcon(entry)} size='large' />
            </ItemImage>
            <ItemContent>
                <ItemHeader>
                    {getHeader(entry)}
                </ItemHeader>
            </ItemContent>
        </Item>
    ));

    return (
        <>
            <Grid>
                <Grid.Row className="adventure-sub-header">
                    <Grid.Column>
                        <Form>
                            <FormGroup inline style={{ margin: 0 }}>
                                <FormField width={16}>
                                    <label style={{ textWrap: 'nowrap' }} htmlFor="search-input">
                                        Поиск по приключению
                                    </label>
                                    <Input
                                        id="search-input"
                                        type="text"
                                        disabled={isLoading}
                                        value={text}
                                        size="small"
                                        className="underlined-input"
                                        onChange={(e) => setText(e.target.value)}
                                    />
                                </FormField>
                                <Button
                                    circular
                                    icon='search'
                                    className="inline-title"
                                    disabled={isLoading || disabled}
                                    title="Поиск"
                                    onClick={() => search(text)}
                                />
                            </FormGroup>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
                {entries && (
                    items.length > 0 ? (
                        <Grid.Row>
                            <Grid.Column>
                                <ItemGroup>{items}</ItemGroup>
                            </Grid.Column>
                        </Grid.Row>
                    ) : (
                        <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
                            <Grid.Column>
                                <Message warning>Ничего не найдено</Message>
                            </Grid.Column>
                        </Grid.Row>
                    )
                )}
            </Grid>
            <EditNoteDialog
                entry={editDialog.entry}
                chapters={chapters}
                persons={persons}
                points={points}
                isOpen={editDialog.isOpen}
                disabled={isLoading || disabled}
                onCancel={cancelEdit}
                onSave={confirmEdit}
                errors={editNoteErrors}
            />
        </>
    );
};

AdventureGlobalSearchTab.propTypes = {
    adventureId: PropTypes.string.isRequired,
    disabled: PropTypes.bool
};

export default AdventureGlobalSearchTab;