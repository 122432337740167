import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form, Button, Dropdown, Modal, FormGroup, Input, Grid, FormField } from "semantic-ui-react";
import MarkdownEditor from "../../../../controls/markdownEditor";
import { escapeRegex } from "../../../../utils/textUtils";
import { getFantasyIconOptions } from "../../../../utils/convertUtils";

const EditNoteDialog = ({
    isOpen,
    entry,
    chapters,
    persons,
    points,
    errors,
    disabled,
    onCancel,
    onSave
}) => {
    const [chapter, setChapter] = useState(null);
    const [person, setPerson] = useState(null);
    const [point, setPoint] = useState(null);
    const [icon, setIcon] = useState('sticky note outline');
    const [name, setName] = useState('');
    const [text, setText] = useState('');

    const icons = getFantasyIconOptions();

    useEffect(() => {
        setText(entry ? entry.text : '');
        setName(entry ? entry.name : '');
        setIcon(entry ? entry.icon : 'sticky note outline');
        setChapter(entry?.chapter?.id);
        setPerson(entry?.person?.id);
        setPoint(entry?.point?.id);
    }, [isOpen]);

    const save = () =>
        onSave({
            ...entry,
            chapter: chapter ? { id: chapter } : null,
            person: person ? { id: person } : null,
            point: point ? { id: point } : null,
            icon,
            name,
            text
        });

    const title = entry?.id ? 'Редактирование заметки' : 'Создание новой заметки';

    return (
        <Modal size="large" open={isOpen}>
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
                <Form>
                    <FormGroup inline>
                        <FormField 
                            width={3}
                            error={errors?.['Icon']}
                            title={errors?.['Icon']}
                        >
                            <label className="required-label" htmlFor="note-icon-select">Иконка <span>*</span></label>
                            <Dropdown
                                id="note-icon-select"
                                selection
                                options={icons}
                                value={icon}
                                search={(options, query) => {
                                    const re = new RegExp(escapeRegex(query), 'i');
                                    return options.filter((opt) => re.test(opt.searchText));
                                }}
                                disabled={disabled}
                                onChange={(e, { value }) => setIcon(value)}
                                className="icon-dropdown"
                            />
                        </FormField>
                        <FormField 
                            width={13}
                            error={errors?.['Name']}
                            title={errors?.['Name']}
                        >
                            <label className="required-label" htmlFor="note-name-input">Заголовок <span>*</span></label>
                            <Input 
                                id="note-name-input"
                                value={name} 
                                disabled={disabled}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </FormField>
                    </FormGroup>
                    <FormGroup inline>
                        <FormField 
                            width={6}
                            error={errors?.['Chapter']}
                            title={errors?.['Chapter']}
                        >
                            <label htmlFor="note-chapter-select">Глава</label>
                            <Dropdown
                                id="note-chapter-select"
                                search
                                selection
                                options={[{},].concat(chapters)}
                                value={chapter}
                                disabled={disabled}
                                onChange={(e, { value }) => setChapter(value)}
                            />
                        </FormField>
                        <FormField 
                            width={6}
                            error={errors?.['Point']}
                            title={errors?.['Point']}
                        >
                            <label htmlFor="note-point-select">Место</label>
                            <Dropdown
                                id="note-point-select"
                                search
                                selection
                                options={[{},].concat(points)}
                                value={point}
                                disabled={disabled}
                                onChange={(e, { value }) => setPoint(value)}
                            />
                        </FormField>
                        <FormField 
                            width={6}
                            error={errors?.['Person']}
                            title={errors?.['Person']}
                        >
                            <label htmlFor="note-person-select">Персонаж</label>
                            <Dropdown
                                id="note-person-select"
                                search
                                selection
                                options={[{},].concat(persons)}
                                value={person}
                                disabled={disabled}
                                onChange={(e, { value }) => setPerson(value)}
                            />
                        </FormField>
                    </FormGroup>
                </Form>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Form>
                                <FormField
                                    error={errors?.['Text']}
                                    title={errors?.['Text']}
                                >
                                    <label className="required-label" htmlFor="note-text-editor">Описание <span>*</span></label>
                                </FormField>
                            </Form>
                            <MarkdownEditor
                                id="note-text-editor"
                                value={text}
                                disabled={disabled}
                                onChange={setText}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onCancel} disabled={disabled}>
                    Отмена
                </Button>
                <Button primary onClick={save} disabled={disabled}>
                    Сохранить
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

EditNoteDialog.propTypes = {
    isOpen: PropTypes.bool,
    entry: PropTypes.object.isRequired,
    chapters: PropTypes.array.isRequired,
    persons: PropTypes.array.isRequired,
    points: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
};

export default EditNoteDialog;