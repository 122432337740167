import { getAllIcons } from "../api/icons";
import { Icon } from "semantic-ui-react";

export const convertEntryToOption = (entry) => {
    return {
        key: entry.id,
        text: entry.name,
        value: entry.id
    };
};

export const getFantasyIconOptions = () => {
    return [
        {
            key: '',
            value: '',
            text: ''
        }
    ].concat(
        getAllIcons().map((key) => ({
            key,
            value: key,
            text: <Icon name={key} size="large"/>,
            searchText: key
        }))
    );
};
