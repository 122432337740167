import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dropdown, Form, FormField, FormGroup, Grid } from "semantic-ui-react";
import MarkdownEditor from "../../../../controls/markdownEditor";
import { loadAdventureChaptersList } from "../../../../api/adventureChapters";
import { convertEntryToOption } from "../../../../utils/convertUtils.jsx";

const AdventurePointInfoTab = ({
    point, 
    onSaveField, 
    disabled
}) => {
    const [description, setDescription] = useState(point.description || '');
    const [chapterId, setChapterId] = useState(point?.chapter?.id);
    const [chapters, setChapters] = useState([]);

    useEffect(() => {
        setChapterId(point?.chapter?.id);

        if (point?.adventureId) {
            loadAdventureChaptersList(point.adventureId).then(result => result && setChapters([{}].concat(result.entries.map(convertEntryToOption))));
        }
    }, [point]);

    const setChapter = (newChapterId) => {
        setChapterId(newChapterId);
        onSaveField('chapterId', newChapterId);
    };

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Form>
                        <FormGroup inline style={{ marginBottom: 0 }}>
                            <FormField width={8}>
                                <label htmlFor="point-chapter-select">Глава</label>
                                <Dropdown
                                    id="point-chapter-select"
                                    selection
                                    options={chapters}
                                    value={chapterId}
                                    disabled={disabled}
                                    onChange={(e, { value }) => setChapter(value)}
                                />
                            </FormField>
                        </FormGroup>
                    </Form>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <MarkdownEditor
                        disabled={disabled}
                        value={description}
                        onChange={setDescription}
                        onBlur={() => onSaveField('description', description)}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

AdventurePointInfoTab.propTypes = {
    point: PropTypes.object.isRequired,
    onSaveField: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

export default AdventurePointInfoTab;