import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { updateAdventureField } from "../../../api/adventures";
import { Button, Grid, Icon } from "semantic-ui-react";
import AutoSaveInput from "../../../controls/autoSaveInput";
import { ADVENTURE_CHAPTERS_HASH, ADVENTURE_INFO_HASH, ADVENTURE_MATERIALS_HASH, ADVENTURE_NOTES_HASH, ADVENTURE_PAGE_LINK, ADVENTURE_PERSONS_HASH, ADVENTURE_PLACES_HASH, ADVENTURE_POINTS_HASH, ADVENTURE_SEARCH_HASH, ADVENTURES_LIST_LINK } from "../../../router/links";

const AdventureHeader = ({
    adventureId,
    adventureName,
    adventurePageTag
}) => {
    const navigate = useNavigate();

    const [isDataLoading, setIsDataLoading] = useState(false);
    const [name, setName] = useState(adventureName);

    useEffect(() => setName(adventureName), [adventureName]);

    const saveNameField = () => {
        setIsDataLoading(true);
        updateAdventureField(adventureId, 'name', name)
            .then(() => setIsDataLoading(false))
            .catch(() => setIsDataLoading(false));
    };
    
    const goBack = () => {
        if (window.history.state?.idx > 0) {
            navigate(-1);
        } else {
            navigate(ADVENTURES_LIST_LINK, { replace: true });
        }
    };

    const adventurePageUrl = ADVENTURE_PAGE_LINK.replace(':id', adventureId);

    return (
        <Grid>
            <Grid.Row className='adventure-header'>
                <div className='header-main'>
                    <div className='header-back'>
                        <Button circular basic icon='arrow left' onClick={goBack} />
                    </div>
                    <div className='header-title'>
                        <AutoSaveInput 
                            transparent 
                            size='big'
                            icon='globe'
                            iconPosition='left'
                            className='title-input'
                            disabled={isDataLoading}
                            value={name} 
                            onChange={(e) => setName(e.target.value)}
                            onBlur={saveNameField}
                        />
                    </div>
                </div>
                <div className='header-links'>
                    <Link 
                        title="Описание"
                        to={adventurePageUrl + ADVENTURE_INFO_HASH} 
                        className={'ui basic circular icon button' + (adventurePageTag === ADVENTURE_INFO_HASH ? ' black' : '')}
                    >
                        <Icon name="info"/>
                    </Link>
                    <Link 
                        title="Главы"
                        to={adventurePageUrl + ADVENTURE_CHAPTERS_HASH} 
                        className={'ui basic circular icon button' + (adventurePageTag === ADVENTURE_CHAPTERS_HASH ? ' black' : '')}
                    >
                        <Icon name="list ol"/>
                    </Link>
                    <Link 
                        title="Персонажи"
                        to={adventurePageUrl + ADVENTURE_PERSONS_HASH} 
                        className={'ui basic circular icon button' + (adventurePageTag === ADVENTURE_PERSONS_HASH ? ' black' : '')}
                    >
                        <Icon name="group"/>
                    </Link>
                    <Link 
                        title="Локации"
                        to={adventurePageUrl + ADVENTURE_PLACES_HASH} 
                        className={'ui basic circular icon button' + (adventurePageTag === ADVENTURE_PLACES_HASH ? ' black' : '')}
                    >
                        <Icon name="map"/>
                    </Link>
                    <Link 
                        title="Места"
                        to={adventurePageUrl + ADVENTURE_POINTS_HASH} 
                        className={'ui basic circular icon button' + (adventurePageTag === ADVENTURE_POINTS_HASH ? ' black' : '')}
                    >
                        <Icon name="map signs"/>
                    </Link>
                    <Link 
                        title="Заметки"
                        to={adventurePageUrl + ADVENTURE_NOTES_HASH} 
                        className={'ui basic circular icon button' + (adventurePageTag === ADVENTURE_NOTES_HASH ? ' black' : '')}
                    >
                        <Icon name="sticky note outline"/>
                    </Link>
                    <Link 
                        title="Материалы"
                        to={adventurePageUrl + ADVENTURE_MATERIALS_HASH} 
                        className={'ui basic circular icon button' + (adventurePageTag === ADVENTURE_MATERIALS_HASH ? ' black' : '')}
                    >
                        <Icon name="folder open outline"/>
                    </Link>
                    <Link 
                        title="Поиск"
                        to={adventurePageUrl + ADVENTURE_SEARCH_HASH} 
                        className={'ui basic circular icon button' + (adventurePageTag === ADVENTURE_SEARCH_HASH ? ' black' : '')}
                    >
                        <Icon name="search"/>
                    </Link>
                </div>
            </Grid.Row>
        </Grid>
    );
};

AdventureHeader.propTypes = {
    adventureId: PropTypes.string.isRequired,
    adventureName: PropTypes.string,
    adventurePageTag: PropTypes.string
};

export default AdventureHeader;