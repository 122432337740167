import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Container, Grid, Icon, Tab } from "semantic-ui-react";
import { ADVENTURE_CHAPTER_INFO_HASH, ADVENTURE_CHAPTER_NOTES_HASH, ADVENTURE_CHAPTER_PLACES_HASH, ADVENTURE_CHAPTER_POINTS_HASH, ADVENTURE_CHAPTERS_HASH } from "../../../router/links";
import AdventureChapterInfoTab from "../tabs/chapter/adventureChapterInfoTab";
import AdventureChapterPointsTab from "../tabs/chapter/adventureChapterPointsTab";
import AdventureChapterNotesTab from "../tabs/chapter/adventureChapterNotesTab";
import { loadAdventureChapterData, updateAdventureChapterField } from "../../../api/adventureChapters";
import AdventurePlacesTab from "../tabs/adventure/adventurePlacesTab";
import { loadAdventurePlaces } from "../../../api/adventurePlace";
import AutoSaveInput from "../../../controls/autoSaveInput";
import AdventureHeader from "../components/adventureHeader";

const AdventureChapterPage = () => {
    const { id } = useParams();
    const { hash } = useLocation();
    
    const [form, setForm] = useState({});
    const [places, setPlaces] = useState([]);
    const [name, setName] = useState('');
    const [activeTab, setActiveTab] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isPlacesLoading, setIsPlacesLoading] = useState(false);

    const reloadPlaces = (chapter) => {
        if (chapter.adventureId) {
            setIsPlacesLoading(true);
            loadAdventurePlaces(chapter.adventureId, null, chapter.id)
                .then(result => {
                    setIsPlacesLoading(false);
                    setPlaces(result);
                })
                .catch(() => setIsPlacesLoading(false));
        }
    };

    const getData = () => {
        setIsLoading(true);
        loadAdventureChapterData(id)
            .then(result => {
                setIsLoading(false);

                setForm(result);
                reloadPlaces(result);
                setName(result.name || '');
                
                document.title = `Overdice - ${result.adventureName || 'приключения'} - ${result.name || 'главы'}`;
            })
            .catch(() => setIsLoading(false));
    }
    useEffect(getData, [id]);

    const saveField = (fieldName, value) => {
        setIsLoading(true);
        updateAdventureChapterField(id, fieldName, value)
            .then(() => setIsLoading(false))
            .catch(() => setIsLoading(false));
    };

    const panes = [
        {
            tag: ADVENTURE_CHAPTER_INFO_HASH,
            render: () => (
                <AdventureChapterInfoTab
                    key={'adventure-chapter-info-' + (form.id || 'none')}
                    disabled={isLoading}
                    chapter={form} 
                    onSaveField={saveField}
                />
            )
        },
        {
            tag: ADVENTURE_CHAPTER_PLACES_HASH,
            render: () => (
                <AdventurePlacesTab
                    isSubPageTab
                    key={'adventure-chapter-places-' + (form.id || 'none')}
                    disabled={isLoading || isPlacesLoading}
                    adventureId={form.adventureId} 
                    chapterId={form.id}
                    chapters={[{ id: form.id, name: form.name }]}
                    places={places}
                    onReload={() => reloadPlaces(form)}
                />
            )
        },
        {
            tag: ADVENTURE_CHAPTER_POINTS_HASH,
            render: () => (
                <AdventureChapterPointsTab
                    key={'adventure-chapter-points-' + (form.id || 'none')}
                    disabled={isLoading}
                    adventureId={form.adventureId} 
                    chapterId={form.id} 
                />
            )
        },
        {
            tag: ADVENTURE_CHAPTER_NOTES_HASH,
            render: () => (
                <AdventureChapterNotesTab
                    key={'adventure-chapter-notes-' + (form.id || 'none')}
                    disabled={isLoading}
                    adventureId={form.adventureId} 
                    chapterId={form.id} 
                />
            )
        }
    ];

    const syncActiveTab = () => {
        if (hash) {
            panes.forEach((tab, index) => {
                if (tab.tag === hash) {
                    setActiveTab(index);
                }
            });
        } else {
            setActiveTab(0);
        }
    };
    useEffect(syncActiveTab, [hash]);

    const getMenu = () => <></>;

    return (
        <Container>
            <AdventureHeader
                adventureId={form.adventureId}
                adventureName={form.adventureName}
                adventurePageTag={ADVENTURE_CHAPTERS_HASH}
            />
            <Grid>
                <Grid.Row className='adventure-sub-header adventure-chapter-header'>
                    <div className='header-main'>
                        <div className='header-title'>
                            <AutoSaveInput 
                                transparent 
                                size='big'
                                icon='list ol'
                                iconPosition='left'
                                className='title-input'
                                disabled={isLoading}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                onBlur={() => saveField('name', name)}
                            />
                        </div>
                    </div>
                    <div className='header-links'>
                        <Link 
                            title="Описание"
                            to={ADVENTURE_CHAPTER_INFO_HASH} 
                            className={'ui basic circular icon tiny button' + (panes[activeTab].tag === ADVENTURE_CHAPTER_INFO_HASH ? ' black' : '')}
                        >
                            <Icon name="info"/>
                        </Link>
                        <Link 
                            title="Локации"
                            to={ADVENTURE_CHAPTER_PLACES_HASH} 
                            className={'ui basic circular icon tiny button' + (panes[activeTab].tag === ADVENTURE_CHAPTER_PLACES_HASH ? ' black' : '')}
                        >
                            <Icon name="map"/>
                        </Link>
                        <Link 
                            title="Места"
                            to={ADVENTURE_CHAPTER_POINTS_HASH} 
                            className={'ui basic circular icon tiny button' + (panes[activeTab].tag === ADVENTURE_CHAPTER_POINTS_HASH ? ' black' : '')}
                        >
                            <Icon name="map signs"/>
                        </Link>
                        <Link 
                            title="Заметки"
                            to={ADVENTURE_CHAPTER_NOTES_HASH} 
                            className={'ui basic circular icon tiny button' + (panes[activeTab].tag === ADVENTURE_CHAPTER_NOTES_HASH ? ' black' : '')}
                        >
                            <Icon name="sticky note outline"/>
                        </Link>
                    </div>
                </Grid.Row>
            </Grid>
            <Tab menu={{ as: getMenu }} panes={panes} activeIndex={activeTab} />
        </Container>
    );
};

export default AdventureChapterPage;