import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Dropdown, Form, FormField, FormGroup, Modal } from "semantic-ui-react";
import ImageUploader from "../../../../controls/imageUploader";

const CreatePlaceDialog = ({
    isOpen,
    disabled,
    title,
    chapters,
    fixedChapterId,
    parentId,
    parentX,
    parentY,
    errors,
    onCancel,
    onSave
}) => {
    const [name, setName] = useState('');
    const [file, setFile] = useState(null);
    const [chapterId, setChapterId] = useState(fixedChapterId);

    useEffect(() => {
        setName('');
        setFile(null);
    }, [isOpen]);

    const saveData = () => {
        const form = new FormData();
        form.append('name', name);
        chapterId && form.append('chapterId', chapterId);
        parentId && form.append('parentPlaceId', parentId);
        parentX && form.append('parentPlaceX', parentX);
        parentY && form.append('parentPlaceY', parentY);
        if (file) {
            form.append('file', file, file ? file.name : 'file');
        }
        onSave(form);
    };

    return (
        <Modal size="small" open={isOpen}>
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
                <Form>
                    <FormGroup>
                        <FormField 
                            width={8}
                            error={errors?.['Name']}
                            title={errors?.['Name']}
                        >
                            <label className="required-label" htmlFor="place-name-input">Название <span>*</span></label>
                            <input
                                id="place-name-input"
                                value={name}
                                disabled={disabled}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </FormField>
                        <FormField 
                            width={8}
                            error={errors?.['ChapterId']}
                            title={errors?.['ChapterId']}
                        >
                            <label htmlFor="place-chapter-select">Глава</label>
                            <Dropdown
                                id="place-chapter-select"
                                search
                                selection
                                options={[{},].concat(chapters)}
                                value={chapterId}
                                disabled={disabled || fixedChapterId}
                                onChange={(e, { value }) => setChapterId(value)}
                            />
                        </FormField>
                    </FormGroup>
                    <FormGroup>
                        <FormField 
                            width={16}
                            error={errors?.['File']}
                            title={errors?.['File']}
                        >
                            <label className="required-label" htmlFor="place-image-uploader">Изображение <span>*</span></label>
                            <ImageUploader 
                                id="place-image-uploader"
                                disabled={disabled}
                                selectedFile={file}
                                onSelectFile={setFile}
                            />
                        </FormField>
                    </FormGroup>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button 
                    onClick={onCancel}
                >
                    Отмена
                </Button>
                <Button 
                    primary 
                    disabled={disabled}
                    onClick={saveData}
                >
                    Сохранить
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

CreatePlaceDialog.propTypes = {
    isOpen: PropTypes.bool,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    chapters: PropTypes.array.isRequired,
    fixedChapterId: PropTypes.string,
    parentId: PropTypes.string,
    parentX: PropTypes.number,
    parentY: PropTypes.number,
    errors: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
};

export default CreatePlaceDialog;