import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Dropdown, DropdownItem, DropdownMenu, Grid, Header, Icon, Item, ItemContent, ItemDescription, ItemGroup, ItemHeader, ItemImage, List, ListItem } from "semantic-ui-react";
import ConfirmationDialog from "../../../../modals/confirmationDialog";
import CreatePlaceDialog from "../../components/modals/createPlaceDialog";
import { createAdventurePlace, deleteAdventurePlace } from "../../../../api/adventurePlace";
import { Link } from "react-router-dom";
import { ADVENTURE_CHAPTER_PAGE_LINK, ADVENTURE_PLACE_PAGE_LINK } from "../../../../router/links";
import { mapLinks } from "../../../../api/externalLinks";
import { loadAdventureChaptersList } from "../../../../api/adventureChapters";
import { convertEntryToOption } from "../../../../utils/convertUtils.jsx";

const AdventurePlacesTab = ({
    adventureId,
    chapterId,
    places,
    disabled,
    onReload,
    isSubPageTab
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [confirmationDialog, setConfirmationDialog] = useState({ isOpen: false });
    const [isNewDialogOpen, setIsNewDialogOpen] = useState(false);
    const [newEntryErrors, setNewEntryErrors] = useState({});
    const [chapters, setChapters] = useState([]);

    useEffect(
        () => {
            setIsLoading(true);
            loadAdventureChaptersList(adventureId)
                .then(result => {
                    setIsLoading(false);
                    result && setChapters(result.entries.map(convertEntryToOption));
                })
                .catch(() => setIsLoading(false));
        }, 
        []
    );

    const onAddNewPlace = (form) => {
        if (adventureId) {
            setIsLoading(true);
            setNewEntryErrors({});
            createAdventurePlace(adventureId, form)
                .then(() => {
                    setIsNewDialogOpen(false);
                    onReload();
                    setIsLoading(false);
                })
                .catch((result) => {
                    setIsLoading(false);
                    setNewEntryErrors(result?.errors);
                });
        }
    };

    const handleDelete = (id, name) => {
        setConfirmationDialog({
            message: `Вы действительно хотите удалить локацию '${name}'?`,
            isOpen: true,
            id
        });
    };

    const cancelDelete = () => {
        setConfirmationDialog({
            isOpen: false
        });
    };

    const confirmDelete = () => {
        const id = confirmationDialog.id;
        cancelDelete();
        setIsLoading(true);
        deleteAdventurePlace(id)
            .then(() => {
                onReload();
                setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
    };

    const items = places.map((entry, ind) => (
        <Item 
            key={'place-' + ind}
            style={{
                paddingLeft: entry.depth > 0 ? (entry.depth * 40) : 0
            }}
        >
            <ItemImage style={{ width: 25, cursor: 'pointer' }}>
                <Icon name="map marker" size='large' />
            </ItemImage>
            <ItemContent>
                <ItemHeader>
                    <Link to={ADVENTURE_PLACE_PAGE_LINK.replace(':id', entry.id)}>
                        {entry.name}
                    </Link>
                    <Icon 
                        className="remove-button"
                        name="trash alternate" 
                        title='Удаление локации'
                        onClick={() => handleDelete(entry.id, entry.name)}
                    />
                </ItemHeader>
                {chapterId || entry.depth > 0 ? null : entry.chapterId && (
                    <ItemDescription>
                        <List horizontal>
                            <ListItem 
                                icon='list ol'
                                content={
                                    <Link to={ADVENTURE_CHAPTER_PAGE_LINK.replace(':id', entry.chapterId)}>
                                        {entry.chapterName}
                                    </Link>
                                }
                            />
                        </List>
                    </ItemDescription>
                )}
            </ItemContent>
        </Item>
    ));

    const headerMenu = (
        <>
            <div className='header-main'>
                <div className='header-title'>
                    <Header size="medium" className="inline-title">
                        Локации
                    </Header>
                    <Button
                        circular
                        icon='plus'
                        size="tiny"
                        className="inline-title"
                        disabled={isLoading || disabled}
                        title="Создание новой"
                        style={{ marginRight: 6 }}
                        onClick={() => {
                            setIsNewDialogOpen(true);                
                            setNewEntryErrors({});
                        }}
                    />
                </div>
            </div>
            <div className='header-links'>
                <Dropdown 
                    icon="external share" 
                    className="ui basic circular icon tiny button"
                    title="Полезные ссылки"
                    direction="left"
                    simple
                >
                    <DropdownMenu>
                        {mapLinks.map((link) => (
                            <DropdownItem key={link.key}>
                                <Link to={link.url} target="__blank">
                                    {link.name}
                                </Link>
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Dropdown>
            </div>
        </>
    );

    const getHeader = () => {
        if (isSubPageTab) {
            return (
                <Grid.Row style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
                    {headerMenu}
                </Grid.Row>
            );
        } else {
            return (
                <Grid.Row className="adventure-sub-header adventure-menu-header">
                    {headerMenu}
                </Grid.Row>
            );
        }
    };

    return (
        <Grid>
            {getHeader()}
            <Grid.Row columns='equal'>
                <Grid.Column>
                    <ItemGroup divided>{items}</ItemGroup>
                </Grid.Column>
            </Grid.Row>
            <CreatePlaceDialog
                isOpen={isNewDialogOpen}
                title='Создание новой локации'
                chapters={chapters}
                fixedChapterId={chapterId}
                onCancel={() => setIsNewDialogOpen(false)}
                onSave={onAddNewPlace}
                errors={newEntryErrors}
            />
            <ConfirmationDialog
                isOpen={confirmationDialog.isOpen}
                title='Удаление локации'
                message={confirmationDialog.message}
                onCancel={cancelDelete}
                onSave={confirmDelete}
            />
        </Grid>
    );
};

AdventurePlacesTab.propTypes = {
    adventureId: PropTypes.string.isRequired,
    chapterId: PropTypes.string,
    places: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    onReload: PropTypes.func.isRequired,
    isSubPageTab: PropTypes.bool
};

export default AdventurePlacesTab;