import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { createAdventurePlace, updateAdventurePlace, replaceAdventurePlaceFile, deleteAdventurePlace } from "../../../../api/adventurePlace";
import { createAdventureNote, deleteAdventureNote, updateAdventureNote } from "../../../../api/adventureNotes";
import MapContainer from "../../../../controls/maps/mapContainer";
import CreatePlaceDialog from '../../components/modals/createPlaceDialog';
import EditNoteDialog from '../../components/modals/editNoteDialog';
import ChangePlaceImageDialog from "../../components/modals/changePlaceImageDialog";
import ConfirmationDialog from "../../../../modals/confirmationDialog";
import { Grid, Header } from "semantic-ui-react";
import { ADVENTURE_PLACE_PAGE_LINK, ADVENTURE_POINT_PAGE_LINK } from "../../../../router/links";
import { createAdventurePoint, deleteAdventurePoint, updateAdventurePoint } from "../../../../api/adventurePoints";
import CreateNamedEntryDialog from "../../../../modals/createNamedEntryDialog";

const AdventurePlaceMapTab = ({
    place,
    chapters,
    persons,
    notes,
    points,
    childPlaces,
    reloadData,
    reloadNotes,
    reloadPoints,
    reloadChildPlaces,
    disabled
}) => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [editNoteDialog, setEditNoteDialog] = useState({ isOpen: false });
    const [newPointDialog, setNewPointDialog] = useState({ isOpen: false });
    const [createPlaceDialog, setCreatePlaceDialog] = useState({ isOpen: false });
    const [changeImageDialog, setChangeImageDialog] = useState({ isOpen: false });
    const [confirmationDialog, setConfirmationDialog] = useState({ isOpen: false });
    const [newEntryErrors, setNewEntryErrors] = useState({});
    const [changeEntryErrors, setChangeEntryErrors] = useState({});
    const [editNoteErrors, setEditNoteErrors] = useState({});
    const [newPointErrors, setNewPointErrors] = useState({});

    const onAddPlace = (x, y) => {
        setNewEntryErrors({});
        setCreatePlaceDialog({
            isOpen: true,
            x, 
            y
        });
    };

    const onAddPoint = (x, y) => {
        setNewPointErrors({});
        setNewPointDialog({
            isOpen: true,
            x, 
            y
        });
    };

    const onAddNote = (x, y) => setEditNoteDialog({
        isOpen: true,
        form: {
            adventure: {
                id: place.adventureId,
                name: place.adventureName
            },
            place: {
                id: place.id,
                name: place.name
            },
            placeX: x,
            placeY: y
        }
    });

    const onChangeImage = () => {
        setChangeEntryErrors({});
        setChangeImageDialog({
            isOpen: true,
            title: `Замена изображения для ${place.name}`
        });
    };

    const contextMenuItems = [
        {
            icon: 'map marker',
            name: 'Новая локация',
            onClick: onAddPlace
        },
        {
            icon: 'map signs',
            name: 'Новое место',
            onClick: onAddPoint
        },
        {
            icon: 'sticky note outline',
            name: 'Новая заметка',
            onClick: onAddNote
        },
        {
            icon: 'sync',
            name: 'Заменить изображение',
            onClick: onChangeImage
        }
    ];

    const closeConfirmation = () => setConfirmationDialog({ isOpen: false });
    const closeChangeImageDialog = () => setChangeImageDialog({ isOpen: false });
    const closeEditNoteDialog = () => setEditNoteDialog({ isOpen: false });
    const closeNewPointDialog = () => setNewPointDialog({ isOpen: false });
    const closeCreatePlaceDialog = () => setCreatePlaceDialog({ isOpen: false });

    const onChangeImageSave = (data) => {
        setIsLoading(true);
        setChangeEntryErrors({});
        replaceAdventurePlaceFile(place.id, data)
            .then(() => {
                closeChangeImageDialog();
                reloadData();
            })
            .catch((result) => {
                setIsLoading(false);
                setChangeEntryErrors(result?.errors);
            });
    };
    
    const onEditNoteSave = (data) => {
        setIsLoading(true);
        setEditNoteErrors({});
        (data.id ? updateAdventureNote(data.id, data) : createAdventureNote(data))
            .then(() => {
                closeEditNoteDialog();
                reloadNotes();
                setIsLoading(false);
            })
            .catch((result) => {
                setIsLoading(false);
                setEditNoteErrors(result?.errors);
            });
    };
        
    const onAddNewPoint = (name) => {
        setIsLoading(true);
        setNewPointErrors({});
        createAdventurePoint({
            adventureId: place.adventureId,
            place: { id: place.id },
            placeX: newPointDialog.x,
            placeY: newPointDialog.y,
            name 
        }).then((result) => {
                closeNewPointDialog();
                if (result?.id) {
                    navigate(ADVENTURE_POINT_PAGE_LINK.replace(':id', result.id));
                } else {
                    setIsLoading(false);
                }
            })
            .catch((result) => {
                setIsLoading(false);
                setNewPointErrors(result?.errors);
            });
    };
    
    const onCreatePlaceSave = (data) => {
        setIsLoading(true);
        setNewEntryErrors({});
        createAdventurePlace(place.adventureId, data)
            .then(() => {
                closeCreatePlaceDialog();
                reloadChildPlaces();
                setIsLoading(false);
            })
            .catch((result) => {
                setIsLoading(false);
                setNewEntryErrors(result?.errors);
            });
    };

    const onDeletePlace = (place) => 
        setConfirmationDialog({
            isOpen: true,
            title: 'Удаление локации',
            message: `Вы действительно хотите удалить локацию '${place.name}'?`,
            onSave: () => {
                closeConfirmation();
                setIsLoading(true);
                deleteAdventurePlace(place.id)
                    .then(() => {
                        reloadChildPlaces();
                        setIsLoading(false);
                    })
                    .catch(() => setIsLoading(false));
            }
        });

    const onMovePlace = (place, x, y) =>
        updateAdventurePlace(place.id, {
            ...place,
            parentPlaceX: x,
            parentPlaceY: y
        })
        .then(() => reloadChildPlaces());

    const onDeletePoint = (point) =>
        setConfirmationDialog({
            isOpen: true,
            title: 'Удаление места',
            message: `Вы действительно хотите удалить место '${point.name}'?`,
            onSave: () => {
                closeConfirmation();
                setIsLoading(true);
                deleteAdventurePoint(point.id)
                    .then(() => {
                        reloadPoints();
                        setIsLoading(false);
                    })
                    .catch(() => setIsLoading(false));
            }
        })

    const onMovePoint = (point, x, y) =>
        updateAdventurePoint(point.id, {
            ...point,
            placeX: x,
            placeY: y
        })
        .then(() => reloadPoints());

    const onEditNote = (note) => {
        setEditNoteErrors({});
        setEditNoteDialog({
            isOpen: true,
            form: note
        });
    };

    const onDeleteNote = (note) =>
        setConfirmationDialog({
            isOpen: true,
            title: 'Удаление заметки',
            message: `Вы действительно хотите удалить заметку '${note.name}'?`,
            onSave: () => {
                closeConfirmation();
                setIsLoading(true);
                deleteAdventureNote(note.id)
                    .then(() => {
                        reloadNotes();
                        setIsLoading(false);
                    })
                    .catch(() => setIsLoading(false));
            }
        });

    const onMoveNote = (note, x, y) => 
        onEditNoteSave({
            ...note,
            placeX: x,
            placeY: y
        });

    const mapPoints = childPlaces.map((childPlace) => (
        {
            icon: 'map marker',
            title: childPlace.name,
            x: childPlace.parentPlaceX,
            y: childPlace.parentPlaceY,
            contextItems: [
                {
                    content: <Header as='h4'>{childPlace.name}</Header>,
                    onClick: () => navigate(ADVENTURE_PLACE_PAGE_LINK.replace(':id', childPlace.id))
                },
                {
                    icon: 'trash alternate',
                    name: 'Удалить локацию',
                    onClick: () => onDeletePlace(childPlace)
                }
            ],
            onDoubleClick: () => navigate(ADVENTURE_PLACE_PAGE_LINK.replace(':id', childPlace.id)),
            onMove: (x, y) => onMovePlace(childPlace, x, y)
        }))
        .concat(points.map((point) => (
        {
            icon: 'map signs',
            title: point.name,
            x: point.placeX,
            y: point.placeY,
            contextItems: [
                {
                    content: <Header as='h4'>{point.name}</Header>,
                    onClick: () => navigate(ADVENTURE_POINT_PAGE_LINK.replace(':id', point.id))
                },
                {
                    icon: 'trash alternate',
                    name: 'Удалить место',
                    onClick: () => onDeletePoint(point)
                }
            ],
            onDoubleClick: () => navigate(ADVENTURE_POINT_PAGE_LINK.replace(':id', point.id)),
            onMove: (x, y) => onMovePoint(point, x, y)
        })))
        .concat(notes.map((note) => (
        {
            icon: note.icon,
            title: note.name,
            x: note.placeX,
            y: note.placeY,
            contextItems: [
                {
                    content: <Header as='h4'>{note.name}</Header>,
                    icon: 'edit',
                    name: 'Править заметку',
                    onClick: () => onEditNote(note)
                },
                {
                    icon: 'trash alternate',
                    name: 'Удалить заметку',
                    onClick: () => onDeleteNote(note)
                }
            ],
            onDoubleClick: () => onEditNote(note),
            onMove: (x, y) => onMoveNote(note, x, y)
        })));

    return (
        <>
            {place.id && <Grid.Row>
                <Grid.Column>
                    <MapContainer 
                        id={place.id}
                        disabled={disabled || isLoading}
                        key={"map-place-" + place.id}
                        mapUrl={'/api/v1/files/adventurePlace/' + place.fileId}
                        points={mapPoints}
                        contextMenuItems={contextMenuItems}
                    />
                </Grid.Column>
            </Grid.Row>}

            <ChangePlaceImageDialog 
                isOpen={changeImageDialog.isOpen}
                disabled={disabled || isLoading}
                title={changeImageDialog.title}
                onSave={onChangeImageSave}
                onCancel={closeChangeImageDialog}
                errors={changeEntryErrors}
            />

            <CreatePlaceDialog 
                isOpen={createPlaceDialog.isOpen}
                disabled={disabled || isLoading}
                title='Создание новой локации'
                chapters={chapters}
                fixedChapterId={place.chapterId}
                parentId={place.id}
                parentX={createPlaceDialog.x}
                parentY={createPlaceDialog.y}
                onSave={onCreatePlaceSave}
                onCancel={closeCreatePlaceDialog}
                errors={newEntryErrors}
            />
            
            <CreateNamedEntryDialog
                isOpen={newPointDialog.isOpen}
                title='Создание нового места'
                onCancel={closeNewPointDialog}
                onSave={onAddNewPoint}
                errors={newPointErrors}
            />

            <EditNoteDialog 
                isOpen={editNoteDialog.isOpen}
                disabled={disabled || isLoading}
                chapters={chapters}
                persons={persons}
                points={points}
                entry={editNoteDialog.form}
                onSave={onEditNoteSave}
                onCancel={closeEditNoteDialog}
                errors={editNoteErrors}
            />
            
            <ConfirmationDialog
                isOpen={confirmationDialog.isOpen}
                title={confirmationDialog.title}
                message={confirmationDialog.message}
                onCancel={closeConfirmation}
                onSave={confirmationDialog.onSave}
            />
        </>
    );
};

AdventurePlaceMapTab.propTypes = {
    place: PropTypes.object.isRequired,
    chapters: PropTypes.array.isRequired,
    persons: PropTypes.array.isRequired,
    notes: PropTypes.array.isRequired,
    points: PropTypes.array.isRequired,
    childPlaces: PropTypes.array.isRequired,
    reloadData: PropTypes.func.isRequired,
    reloadNotes: PropTypes.func.isRequired,
    reloadPoints: PropTypes.func.isRequired,
    reloadChildPlaces: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

export default AdventurePlaceMapTab;