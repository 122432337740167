import React from "react";
import PropTypes from "prop-types";
import NotesList from "../../components/notesList";

const AdventureChapterNotesTab = ({
    adventureId,
    chapterId,
    disabled
}) => {
    return (
        <NotesList
            adventureId={adventureId}
            chapterId={chapterId}
            disabled={disabled}
        />
    );
};

AdventureChapterNotesTab.propTypes = {
    adventureId: PropTypes.string.isRequired,
    chapterId: PropTypes.string,
    disabled: PropTypes.bool
};

export default AdventureChapterNotesTab;