import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Dropdown, DropdownItem, DropdownMenu, Grid, Header, Icon, Item, ItemContent, ItemDescription, ItemGroup, ItemHeader, Pagination } from "semantic-ui-react";
import CreateNamedEntryDialog from "../../../../modals/createNamedEntryDialog";
import ConfirmationDialog from "../../../../modals/confirmationDialog";
import { Link, useNavigate } from "react-router-dom";
import { ADVENTURE_PERSON_PAGE_LINK } from "../../../../router/links";
import { createAdventurePerson, deleteAdventurePerson, loadAdventurePersonsList } from "../../../../api/adventurePersons";
import { personLinks } from "../../../../api/externalLinks";

const AdventurePersonsTab = ({
    adventureId,
    disabled
}) => {
    const navigate = useNavigate();

    const [persons, setPersons] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isNewDialogOpen, setIsNewDialogOpen] = useState(false);
    const [newEntryErrors, setNewEntryErrors] = useState({});
    const [confirmationDialog, setConfirmationDialog] = useState({ isOpen: false });
    const [pageNumber, setPageNumber] = useState(1);
    const [pagesCount, setPagesCount] = useState(1);
    
    const reloadPersons = () => {
        if (adventureId) {
            setIsLoading(true);
            loadAdventurePersonsList(adventureId, pageNumber)
                .then(result => {
                    setIsLoading(false);
                    if (result) {
                        setPersons(result.entries);
                        setPageNumber(result.pageNumber || 1);
                        setPagesCount(result.pagesCount || 1);
                    }
                })
                .catch(() => setIsLoading(false));
        }
    };
    
    useEffect(reloadPersons, [adventureId, pageNumber]);

    const onAddNewPerson = (name) => {
        if (adventureId) {
            setIsLoading(true);
            setNewEntryErrors({});
            createAdventurePerson(adventureId, { name })
                .then((result) => {
                    setIsNewDialogOpen(false);
                    if (result?.id) {
                        navigate(ADVENTURE_PERSON_PAGE_LINK.replace(':id', result.id));
                    } else {
                        reloadPersons();
                        setIsLoading(false);
                    }
                })
                .catch((result) => {
                    setIsLoading(false);
                    setNewEntryErrors(result?.errors);
                });
        }
    };

    const handleDelete = (id, name) => {
        setConfirmationDialog({
            message: `Вы действительно хотите удалить персонажа '${name}'?`,
            isOpen: true,
            id
        });
    };

    const cancelDelete = () => {
        setConfirmationDialog({
            isOpen: false
        });
    };

    const confirmDelete = () => {
        const id = confirmationDialog.id;
        cancelDelete();
        setIsLoading(true);
        deleteAdventurePerson(id)
            .then(() => {
                reloadPersons();
                setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
    };

    const items = persons.map((entry, ind) => (
        <Item key={'person-' + ind}>
            <Icon name="user circle" size="huge" />
            <ItemContent>
                <ItemHeader>
                    <Link to={ADVENTURE_PERSON_PAGE_LINK.replace(':id', entry.id)}>
                        {entry.name}
                    </Link>
                    <Icon 
                        className="remove-button"
                        name="trash alternate" 
                        title='Удаление персонажа'
                        onClick={() => handleDelete(entry.id, entry.name)}
                    />
                </ItemHeader>
                <ItemDescription>{entry.shortDescription}</ItemDescription>
            </ItemContent>
        </Item>
    ));

    return (
        <Grid>
            <Grid.Row className="adventure-sub-header adventure-menu-header">
                <div className='header-main'>
                    <div className='header-title'>
                        <Header size="medium" className="inline-title">
                            Персонажи
                        </Header>
                        <Button
                            circular
                            icon='plus'
                            size="tiny"
                            className="inline-title"
                            disabled={isLoading || disabled}
                            title="Создание нового"
                            style={{ marginRight: 6 }}
                            onClick={() => {
                                setIsNewDialogOpen(true);
                                setNewEntryErrors({});
                            }}
                        />
                    </div>
                </div>
                <div className='header-links'>
                    <Dropdown 
                        icon="external share" 
                        className="ui basic circular icon tiny button"
                        title="Полезные ссылки"
                        direction="left"
                        simple
                    >
                        <DropdownMenu>
                            {personLinks.map((link) => (
                                <DropdownItem key={link.key}>
                                    <Link to={link.url} target="__blank">
                                        {link.name}
                                    </Link>
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    <ItemGroup divided>{items}</ItemGroup>
                </Grid.Column>
            </Grid.Row>
            {pagesCount > 1 ? (
                <Grid.Row>
                    <Grid.Column>
                        <Pagination 
                            defaultActivePage={pageNumber} 
                            totalPages={pagesCount} 
                            secondary
                            onPageChange={(e, { activePage }) => setPageNumber(activePage)}
                        />
                    </Grid.Column>
                </Grid.Row>
            ) : null}
            <CreateNamedEntryDialog
                isOpen={isNewDialogOpen}
                title='Создание нового персонажа'
                onCancel={() => setIsNewDialogOpen(false)}
                onSave={onAddNewPerson}
                errors={newEntryErrors}
            />
            <ConfirmationDialog
                isOpen={confirmationDialog.isOpen}
                title='Удаление персонажа'
                message={confirmationDialog.message}
                onCancel={cancelDelete}
                onSave={confirmDelete}
            />
        </Grid>
    );
};

AdventurePersonsTab.propTypes = {
    adventureId: PropTypes.string.isRequired,
    disabled: PropTypes.bool
};

export default AdventurePersonsTab;