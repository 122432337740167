import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Dropzone from 'react-dropzone';

const ImageUploader = ({
    disabled,
    selectedFile,
    onSelectFile
}) => {
    const imgRef = useRef(null);

    const [previewSrc, setPreviewSrc] = useState(null);
    const [imageSize, setImageSize] = useState('');

    useEffect(() => {
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = (e) => setPreviewSrc(e.target.result);
            reader.readAsDataURL(selectedFile);
        }
        imgRef.current.onload = () => setImageSize(`, ${imgRef.current.naturalWidth}x${imgRef.current.naturalHeight}`);
    }, [selectedFile]);

    return (
        <Dropzone 
            accept={{
                'image/apng': [],
                'image/avif': [],
                'image/gif': [],
                'image/jpeg': [],
                'image/png': [],
                'image/svg+xml': [],
                'image/webp': []
            }}
            disabled={disabled}
            maxFiles={1}
            maxSize={10485760}
            multiple={false}
            onDrop={(files) => onSelectFile(files?.length > 0 ? files[0] : null)}
        >
            {({getRootProps, getInputProps}) => (
                <section className="container">
                    <div {...getRootProps({className: 'dropzone'})}>
                        <input {...getInputProps()} />
                        <p>Перетащите файл в эту область или нажмите для выбора</p>
                        <div className={"dropzone-preview" + (selectedFile && imageSize ? "" : " no-selection")}>
                            <img 
                                alt="Выбранное изображение"
                                ref={imgRef}
                                src={previewSrc}
                            />
                        </div>
                        {selectedFile && (
                            <p className="dropzone-file">
                                {selectedFile.name}
                                {imageSize}
                            </p>
                        )}
                    </div>
                </section>
            )}
        </Dropzone>
    );
};

ImageUploader.propTypes = {
    disabled: PropTypes.bool,
    selectedFile: PropTypes.object,
    onSelectFile: PropTypes.func.isRequired
};

export default ImageUploader;