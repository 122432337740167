import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { Button, Dropdown, Form, FormField, FormGroup, Grid, Header, Icon, Item, ItemContent, ItemDescription, ItemGroup, ItemHeader, ItemImage, List, ListItem, Pagination, Segment } from "semantic-ui-react";
import { ADVENTURE_CHAPTER_PAGE_LINK, ADVENTURE_PLACE_PAGE_LINK, ADVENTURE_POINT_PAGE_LINK } from "../../../router/links";
import { loadAdventureChaptersList } from "../../../api/adventureChapters";
import { loadAdventurePlaces } from "../../../api/adventurePlace";
import { createAdventurePoint, deleteAdventurePoint, loadAdventurePointsList } from "../../../api/adventurePoints";
import ConfirmationDialog from "../../../modals/confirmationDialog";
import CreateNamedEntryDialog from "../../../modals/createNamedEntryDialog";
import { convertEntryToOption } from "../../../utils/convertUtils.jsx";

const PointsList = ({
    adventureId,
    chapterId,
    placeId,
    parentPointId,
    disabled,
    rowClassName
}) => {
    const navigate = useNavigate();

    const [isNewDialogOpen, setIsNewDialogOpen] = useState(false);
    const [newEntryErrors, setNewEntryErrors] = useState({});
    const [confirmationDialog, setConfirmationDialog] = useState({ isOpen: false });
    const [chapterIdFilter, setChapterIdFilter] = useState(chapterId);
    const [placeIdFilter, setPlaceIdFilter] = useState(placeId);
    const [isLoading, setIsLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [pagesCount, setPagesCount] = useState(1);
    const [points, setPoints] = useState([]);
    const [chapters, setChapters] = useState([]);
    const [places, setPlaces] = useState([]);

    useEffect(() => setChapterIdFilter(chapterId), [chapterId]);
    useEffect(() => setPlaceIdFilter(placeId), [placeId]);
    
    const reloadList = () => {
        if (adventureId) {
            setIsLoading(true);
            loadAdventurePointsList(adventureId, chapterIdFilter, placeIdFilter, parentPointId, pageNumber)
                .then(result => {
                    setIsLoading(false);
                    if (result) {
                        setPoints(result.entries);
                        setPageNumber(result.pageNumber || 1);
                        setPagesCount(result.pagesCount || 1);
                    }
                })
                .catch(() => setIsLoading(false));
        }
    };

    const loadOptions = () => {
        if (adventureId) {
            loadAdventureChaptersList(adventureId).then(result => result && setChapters([{}].concat(result.entries.map(convertEntryToOption))));
            loadAdventurePlaces(adventureId, null, null).then(result => setPlaces([{}].concat(result.map(convertEntryToOption))));
        }
    };

    useEffect(reloadList, [adventureId, chapterIdFilter, placeIdFilter, parentPointId, pageNumber]);
    useEffect(loadOptions, [adventureId]);
    
    const onAddNewPoint = (name) => {
        if (adventureId) {
            setIsLoading(true);
            setNewEntryErrors({});
            
            createAdventurePoint({
                adventureId,
                chapter: chapterIdFilter ? { id: chapterIdFilter } : null,
                parentPointId,
                name 
            }).then((result) => {
                setIsNewDialogOpen(false);
                if (result?.id) {
                    navigate(ADVENTURE_POINT_PAGE_LINK.replace(':id', result.id));
                } else {
                    setIsLoading(false);
                }
            })
            .catch((result) => {
                setIsLoading(false);
                setNewEntryErrors(result?.errors);
            });
        }
    };

    const handleDelete = (id) => {
        setConfirmationDialog({
            message: `Вы действительно хотите удалить место?`,
            isOpen: true,
            id
        });
    };

    const cancelDelete = () => {
        setConfirmationDialog({
            isOpen: false
        });
    };

    const confirmDelete = () => {
        const id = confirmationDialog.id;
        cancelDelete();
        setIsLoading(true);
        deleteAdventurePoint(id)
            .then(reloadList)
            .catch(() => setIsLoading(false));
    };

    const items = points.map((entry, ind) => (
        <Item key={'point-' + ind}>
            <ItemImage style={{ width: 30 }}>
                <Icon name='map signs' size='big' />
            </ItemImage>
            <ItemContent>
                <ItemHeader>
                    <Link to={ADVENTURE_POINT_PAGE_LINK.replace(':id', entry.id)}>
                        {entry.name}
                    </Link>
                </ItemHeader>
                <ItemDescription>
                    <List horizontal>
                        {entry.chapter ? (
                            <ListItem 
                                icon='list ol'
                                content={
                                <Link to={ADVENTURE_CHAPTER_PAGE_LINK.replace(':id', entry.chapter.id)}>
                                    {entry.chapter.name}
                                </Link>
                                }
                            />
                        ) : null}
                        {entry.place ? (
                            <ListItem 
                                icon='map'
                                content={
                                    <Link to={ADVENTURE_PLACE_PAGE_LINK.replace(':id', entry.place.id)}>
                                        {entry.place.name}
                                    </Link>
                                }
                            />
                        ) : null}
                        <ListItem>
                            <Icon 
                                className="remove-button"
                                name="trash alternate" 
                                title='Удаление места'
                                onClick={() => handleDelete(entry.id)}
                            />
                        </ListItem>
                    </List>
                </ItemDescription>
            </ItemContent>
        </Item>
    ));

    return (
        <>
            <Grid>
                <Grid.Row className={rowClassName}>
                    <Grid.Column>
                        <Header size="medium" className="inline-title">
                            Места
                        </Header>
                        <Button
                            circular
                            icon='plus'
                            size="tiny"
                            className="inline-title"
                            disabled={isLoading || disabled}
                            title="Создание нового"
                            onClick={() => {
                                setIsNewDialogOpen(true);
                                setNewEntryErrors({});
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ paddingTop: '0px' }}>
                    <Grid.Column>
                        <Segment>
                            <Grid>
                                <Grid.Row style={{ paddingBottom: '0px' }}>
                                    <Grid.Column>
                                        <Form size="small">
                                            <FormGroup inline>
                                                <FormField width={9} title="Фильтр по главам">
                                                    <Icon name='list ol' size="large" />
                                                    <Dropdown 
                                                        search
                                                        selection
                                                        clearable
                                                        value={chapterIdFilter}
                                                        onChange={(e, { value }) => setChapterIdFilter(value)}
                                                        options={chapters}
                                                        disabled={disabled || isLoading || chapterId}
                                                    />
                                                </FormField>
                                                <FormField width={9} title="Фильтр по локациям">
                                                    <Icon name='map' size="large" />
                                                    <Dropdown 
                                                        search
                                                        selection
                                                        clearable
                                                        value={placeIdFilter}
                                                        onChange={(e, { value }) => setPlaceIdFilter(value)}
                                                        options={places}
                                                        disabled={disabled || isLoading || placeId}
                                                    />
                                                </FormField>
                                            </FormGroup>
                                        </Form>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <ItemGroup divided>{items}</ItemGroup>
                    </Grid.Column>
                </Grid.Row>
                {pagesCount > 1 ? (
                    <Grid.Row>
                        <Grid.Column>
                            <Pagination 
                                defaultActivePage={pageNumber} 
                                totalPages={pagesCount} 
                                secondary
                                onPageChange={(e, { activePage }) => setPageNumber(activePage)}
                            />
                        </Grid.Column>
                    </Grid.Row>
                ) : null}
            </Grid>
            <CreateNamedEntryDialog
                isOpen={isNewDialogOpen}
                title='Создание нового места'
                onCancel={() => setIsNewDialogOpen(false)}
                onSave={onAddNewPoint}
                errors={newEntryErrors}
            />
            <ConfirmationDialog
                isOpen={confirmationDialog.isOpen}
                title='Удаление места'
                message={confirmationDialog.message}
                onCancel={cancelDelete}
                onSave={confirmDelete}
            />
        </>
    );
};

PointsList.propTypes = {
    adventureId: PropTypes.string.isRequired,
    chapterId: PropTypes.string,
    placeId: PropTypes.string,
    parentPointId: PropTypes.string,
    disabled: PropTypes.bool,
    rowClassName: PropTypes.string
};

export default PointsList;