import React from "react";
import PropTypes from "prop-types";
import NotesList from "../../components/notesList";

const AdventurePersonNotesTab = ({
    adventureId,
    personId,
    disabled
}) => {
    return (
        <NotesList
            adventureId={adventureId}
            personId={personId}
            disabled={disabled}
        />
    );
};

AdventurePersonNotesTab.propTypes = {
    adventureId: PropTypes.string.isRequired,
    personId: PropTypes.string,
    disabled: PropTypes.bool
};

export default AdventurePersonNotesTab;