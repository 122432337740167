import React from "react";
import PropTypes from "prop-types";
import PointsList from "../../components/pointsList";

const AdventurePointsTab = ({
    adventureId,
    disabled
}) => {
    return (
        <PointsList
            adventureId={adventureId}
            disabled={disabled}
            rowClassName='adventure-sub-header'
        />
    );
};

AdventurePointsTab.propTypes = {
    adventureId: PropTypes.string.isRequired,
    disabled: PropTypes.bool
};

export default AdventurePointsTab;