import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, FormField, Grid } from "semantic-ui-react";
import MarkdownEditor from "../../../../controls/markdownEditor";
import AutoSaveInput from "../../../../controls/autoSaveInput";

const AdventureChapterInfoTab = ({
    chapter, 
    onSaveField, 
    disabled
}) => {
    const [shortDescription, setShortDescription] = useState(chapter.shortDescription || '');
    const [description, setDescription] = useState(chapter.description || '');

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Form>
                        <FormField>
                            <label htmlFor="chapter-description-input">Краткое описание</label>
                            <AutoSaveInput
                                id="chapter-description-input"
                                textarea
                                rows={2}
                                disabled={disabled}
                                value={shortDescription}
                                onChange={(e) => setShortDescription(e.target.value)}
                                onBlur={() => onSaveField('shortDescription', shortDescription)}
                            />
                        </FormField>
                    </Form>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <MarkdownEditor
                        disabled={disabled}
                        value={description}
                        onChange={setDescription}
                        onBlur={() => onSaveField('description', description)}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

AdventureChapterInfoTab.propTypes = {
    chapter: PropTypes.object.isRequired,
    onSaveField: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

export default AdventureChapterInfoTab;