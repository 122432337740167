import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Dimmer, Loader } from "semantic-ui-react";

import MainRoute from '../router';
import AppHeader from "./header";
import { LOGIN_LINK, PASSWORD_BASE_LINK, PUBLIC_PAGE_PREFIX, REGISTER_LINK } from "../router/links";
import { hasToken, apiService, setAccessToken } from "../utils/apiService";
import DicesWidget from "../controls/dicesWidget";

const AppAuthWrapper = () => {
    const [user, setUser] = useState({});

    const navigate = useNavigate();

    const isNoAuthPage = window.location.href.endsWith(LOGIN_LINK)
        || window.location.href.endsWith(REGISTER_LINK)
        || window.location.href.indexOf(PASSWORD_BASE_LINK) >= 0
        || window.location.href.indexOf(PUBLIC_PAGE_PREFIX) >= 0;

    const logout = () => {
        setUser({});
        setAccessToken(null);
        navigate(LOGIN_LINK);
    };

    const refreshToken = () => {
        if (hasToken()) {
            apiService.get('/v1/users/refresh')
                .then(({ token }) => {
                    setAccessToken(token);
                })
                .catch(logout);
        }
    };

    useEffect(
        () => {
            if (!isNoAuthPage) {
                if (!hasToken()) {
                    logout();
                } else if (!user?.email) {
                    apiService.get('/v1/users/info')
                        .then(result => setUser(result))
                        .catch(logout);
                }
            }
        }
    );

    useEffect(
        () => {
            refreshToken();
            const timer = setInterval(refreshToken, 600000);
            return () => clearInterval(timer);
        }
    );

    if (isNoAuthPage) {
        return (
            <MainRoute user={user} />
        );
    } else if (user?.email) {
        return (
            <>
                <AppHeader user={user} logout={logout} />
                <MainRoute user={user} />
                <DicesWidget />
            </>
        );
    } else {
        return (
            <Dimmer active inverted>
                <Loader size="huge">Loading</Loader>
            </Dimmer>
        )
    }
};

export default AppAuthWrapper;