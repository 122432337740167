import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { useNavigate } from 'react-router-dom';
import { Button, Form, Grid, Message, Segment } from 'semantic-ui-react';

import { REGISTER_LINK, RESTORE_PASSWORD_LINK } from '../../router/links';
import { apiService, setAccessToken } from '../../utils/apiService';
import LoginLogo from '../header/loginLogo';

const LoginPage = ({ user }) => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [inProgress, setInProgress] = useState(false);

    useEffect(
        () => {
            if (user?.email) {
                navigate("/");
            } else {
                document.title = "Overdice";
            }
        }
    );

    const onLogin = () => {
        setInProgress(true);
        apiService.post('/v1/users/login', { email, password })
            .then(({ token }) => {
                setInProgress(false);
                setAccessToken(token);
                navigate("/");
            })
            .catch(() => {
                setInProgress(false);
            });
    };

    return (
        <Grid textAlign='center' verticalAlign='middle' style={{ height: '100vh' }}>
            <Grid.Column style={{ maxWidth: 400 }}>
                <LoginLogo />
                <Form size='large'>
                    <Segment stacked>
                        <Form.Input 
                            fluid
                            icon='user'
                            iconPosition='left'
                            placeholder='Email'
                            autocomplete='on'
                            id='email'
                            type='email'
                            disabled={inProgress}
                            onChange={(e, { value }) => setEmail(value)}
                        />
                        <Form.Input 
                            fluid
                            icon='lock'
                            iconPosition='left'
                            placeholder='Пароль'
                            id='password'
                            type='password'
                            disabled={inProgress}
                            onChange={(e, { value }) => setPassword(value)}
                        />
                        <Button fluid size='large' onClick={onLogin} disabled={inProgress}>
                            Войти
                        </Button>
                    </Segment>
                </Form>
                <Message>
                    <a href={REGISTER_LINK}>Зарегистрироваться</a> / <a href={RESTORE_PASSWORD_LINK}>Восстановить пароль</a>
                </Message>
            </Grid.Column>
        </Grid>
    );
};

LoginPage.propTypes = {
    user: PropTypes.object
};

export default LoginPage;