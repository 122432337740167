import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { loadAdventurePlaceData, loadAdventurePlaces, updateAdventurePlace } from "../../../api/adventurePlace";
import { loadAdventureNotesList } from "../../../api/adventureNotes";
import { loadAdventureChaptersList } from "../../../api/adventureChapters";
import { loadAdventurePersonsList } from "../../../api/adventurePersons";
import { loadAdventurePointsList } from "../../../api/adventurePoints";
import { Container, Dropdown, DropdownItem, DropdownMenu, Grid, Icon, Tab } from "semantic-ui-react";
import { ADVENTURE_PLACE_DESCRIPTION_HASH, ADVENTURE_PLACE_MAP_HASH, ADVENTURE_PLACE_PAGE_LINK, ADVENTURE_PLACES_HASH, PUBLIC_ADVENTURE_MATERIAL_PAGE } from "../../../router/links";
import AdventurePlaceMapTab from "../tabs/place/adventurePlaceMapTab";
import AdventurePlaceDescriptionTab from "../tabs/place/adventurePlaceDescriptionTab";
import { mapLinks } from "../../../api/externalLinks";
import AutoSaveInput from "../../../controls/autoSaveInput";
import AdventureHeader from "../components/adventureHeader";
import { convertEntryToOption } from "../../../utils/convertUtils.jsx";

const AdventurePlacePage = () => {
    const { id } = useParams();
    const { hash } = useLocation();
    
    const [form, setForm] = useState({});
    const [name, setName] = useState('');
    const [chapters, setChapters] = useState([]);
    const [persons, setPersons] = useState([]);
    const [childPlaces, setChildPlaces] = useState([]);
    const [notes, setNotes] = useState([]);
    const [points, setPoints] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const loadChapters = (adventureId) => {
        if (adventureId) {
            loadAdventureChaptersList(adventureId)
                .then(result => result && setChapters(result.entries.map(convertEntryToOption)));
        }
    };

    const loadPersons = (adventureId) => {
        if (adventureId) {
            loadAdventurePersonsList(adventureId)
                .then(result => result && setPersons(result.entries.map(convertEntryToOption)));
        }
    };

    const reloadChildPlaces = (place) => {
        loadAdventurePlaces(place.adventureId, place.id)
            .then(result => {
                setChildPlaces(result);
                setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
    };

    const reloadNotes = (place) => {
        loadAdventureNotesList(place.adventureId, null, null, place.id, null, null, null)
            .then(result => result && setNotes(result.entries));
    };

    const reloadPoints = (place) => {
        loadAdventurePointsList(place.adventureId, null, place.id, null, null)
            .then(result => result && setPoints(result.entries));
    };

    const getData = () => {
        setIsLoading(true);
        loadAdventurePlaceData(id)
            .then(result => {
                setForm(result);
                setName(result.name || '');
                
                document.title = `Overdice - ${result.adventureName || 'приключения'} - ${result.name || 'локации'}`;

                reloadChildPlaces(result);
                reloadNotes(result);
                reloadPoints(result);
                loadChapters(result.adventureId);
                loadPersons(result.adventureId);
            })
            .catch(() => setIsLoading(false));
    }
    useEffect(getData, [id]);

    const onRename = () => {
        updateAdventurePlace(form.id, {
            ...form,
            name
        });
    };

    const saveDescription = (value) => {
        updateAdventurePlace(form.id, {
            ...form,
            description: value
        });
    };

    const panes = [
        {
            tag: ADVENTURE_PLACE_MAP_HASH,
            render: () => (
                <AdventurePlaceMapTab
                    key={'adventure-place-map-' + (form.id || 'none')}
                    disabled={isLoading}
                    place={form} 
                    chapters={chapters}
                    persons={persons}
                    notes={notes}
                    points={points}
                    childPlaces={childPlaces}
                    reloadData={getData}
                    reloadNotes={() => reloadNotes(form)}
                    reloadPoints={() => reloadPoints(form)}
                    reloadChildPlaces={() => reloadChildPlaces(form)}
                />
            )
        },
        {
            tag: ADVENTURE_PLACE_DESCRIPTION_HASH,
            render: () => (
                <AdventurePlaceDescriptionTab
                    key={'adventure-place-description-' + (form.id || 'none')}
                    disabled={isLoading}
                    place={form} 
                    saveDescription={saveDescription}
                />
            )
        }
    ];

    const syncActiveTab = () => {
        if (hash) {
            panes.forEach((tab, index) => {
                if (tab.tag === hash) {
                    setActiveTab(index);
                }
            });
        } else {
            setActiveTab(0);
        }
    };
    useEffect(syncActiveTab, [hash]);

    const getMenu = () => <></>;

    return (
        <Container>
            <AdventureHeader
                adventureId={form.adventureId}
                adventureName={form.adventureName}
                adventurePageTag={ADVENTURE_PLACES_HASH}
            />
            <Grid>
                <Grid.Row className='adventure-sub-header adventure-place-header'>
                    <div className='header-main'>
                        <div className='header-title'>
                            <AutoSaveInput 
                                transparent 
                                size='big'
                                icon='map marker'
                                iconPosition='left'
                                className='title-input'
                                disabled={isLoading}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                onBlur={onRename}
                            />
                        </div>
                    </div>
                    <div className='header-links'>
                        {form.parentPlaceId && form.parentPlaceName ? (
                            <Link 
                                to={ADVENTURE_PLACE_PAGE_LINK.replace(':id', form.parentPlaceId)} 
                                className="ui basic circular icon labeled tiny button header-up"
                            >
                                <Icon name="level up"/>
                                <span className="up-label">{form.parentPlaceName}</span>
                            </Link>
                        ) : (
                            <span className="header-up" style={{ display: 'inline-block', marginLeft: '6px' }}></span>
                        )}
                        <Link 
                            title="Локация"
                            to={ADVENTURE_PLACE_MAP_HASH} 
                            className={'ui basic circular icon tiny button' + (panes[activeTab].tag === ADVENTURE_PLACE_MAP_HASH ? ' black' : '')}
                        >
                            <Icon name="map outline"/>
                        </Link>
                        <Link 
                            title="Описание"
                            to={ADVENTURE_PLACE_DESCRIPTION_HASH} 
                            className={'ui basic circular icon tiny button' + (panes[activeTab].tag === ADVENTURE_PLACE_DESCRIPTION_HASH ? ' black' : '')}
                        >
                            <Icon name="edit outline"/>
                        </Link>
                        <Link 
                            title="Открыть как материал"
                            to={PUBLIC_ADVENTURE_MATERIAL_PAGE.replace(':adventureId', form.adventureId).replace(':materialType', 'adventureplace').replace(':materialId', form.id)} 
                            target="__blank"
                            className={'ui basic circular icon tiny button'}
                        >
                            <Icon name="eye"/>
                        </Link>
                        <Dropdown 
                            title="Полезные ссылки"
                            icon="external share" 
                            className="ui basic circular icon tiny button"
                            direction="left"
                            simple
                        >
                            <DropdownMenu>
                                {mapLinks.map((link) => (
                                    <DropdownItem key={link.key}>
                                        <Link to={link.url} target="__blank">
                                            {link.name}
                                        </Link>
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </Grid.Row>
            </Grid>
            <Tab menu={{ as: getMenu }} panes={panes} activeIndex={activeTab} />

        </Container>
    );
};

export default AdventurePlacePage;