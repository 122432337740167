import React from "react";
import PropTypes from "prop-types";
import NotesList from "../../components/notesList";

const AdventureNotesTab = ({
    adventureId,
    disabled
}) => {
    return (
        <NotesList
            adventureId={adventureId}
            disabled={disabled}
            rowClassName='adventure-sub-header'
        />
    );
};

AdventureNotesTab.propTypes = {
    adventureId: PropTypes.string.isRequired,
    disabled: PropTypes.bool
};

export default AdventureNotesTab;