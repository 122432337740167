import React, { useEffect, useState } from 'react';
import { loadAdventuresList } from '../../api/adventures';
import { Card, CardContent, CardHeader, Container, Dimmer, Feed, FeedContent, FeedDate, FeedEvent, FeedLabel, FeedSummary, Icon, Label, Loader } from 'semantic-ui-react';
import { ADVENTURE_PAGE_LINK, ADVENTURES_LIST_LINK } from '../../router/links';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import 'moment/locale/ru';

const DashboardPage = () => {
    const [adventures, setAdventures] = useState([]);
    const [isAdventuresLoading, setIsAdventuresLoading] = useState(false);
    
    useEffect(() => {
        document.title = "Overdice";

        setIsAdventuresLoading(true);
        loadAdventuresList()
            .then(result => {
                setAdventures((result?.entries) || []);
                setIsAdventuresLoading(false);
            })
            .catch(() => setIsAdventuresLoading(false));
    }, []);

    const getAdventuresCard = () => {
        if (isAdventuresLoading) {
            return (
                <Card>
                    <Dimmer active inverted>
                        <Loader />
                    </Dimmer>
                </Card>
            );
        } else {
            const lastAdventures = adventures.slice(0, 3);
            return (
                <Card>
                    <CardContent>
                        <CardHeader>
                            Приключения
                        </CardHeader>
                    </CardContent>
                    {adventures.length > 0 ? (
                        <>
                            <CardContent>
                                <Feed>
                                    {lastAdventures.map((item, index) => (
                                        <FeedEvent key={"adventures-feed-" + index}>
                                            <FeedLabel>
                                                <Icon name='globe' />
                                            </FeedLabel>
                                            <FeedContent>
                                                <FeedDate>
                                                    <Moment fromNow utc>{item.updatedAt}</Moment>
                                                </FeedDate>
                                                <FeedSummary>
                                                    <Link to={ADVENTURE_PAGE_LINK.replace(':id', item.id)}>{item.name}</Link>
                                                </FeedSummary>
                                            </FeedContent>
                                        </FeedEvent>
                                    ))}
                                </Feed>
                            </CardContent>
                            <CardContent>
                                <Link to={ADVENTURES_LIST_LINK}>Полный список</Link>
                                <Label color='teal' size='small' circular style={{ marginLeft: 4 }}>
                                    {adventures.length}
                                </Label>
                            </CardContent>
                        </>
                    ) : (
                        <>
                            <CardContent>
                                Вы ещё не создали ни одного приключения.
                            </CardContent>
                            <CardContent>
                                <Link to={ADVENTURES_LIST_LINK}>Создать</Link>
                            </CardContent>
                        </>
                    )}
                </Card>
            );
        }
    };

    return (
        <Container>
            {getAdventuresCard()}
        </Container>
    );
};

export default DashboardPage;