export const getAllIcons = () => [
    'fantasy-icon-armor',
    'fantasy-icon-axe',
    'fantasy-icon-bomb',
    'fantasy-icon-magic-book',
    'fantasy-icon-arrow',
    'fantasy-icon-castle',
    'fantasy-icon-cauldron',
    'fantasy-icon-coin',
    'fantasy-icon-crossbow',
    'fantasy-icon-crown',
    'fantasy-icon-hourglass',
    'fantasy-icon-healing-potion',
    'fantasy-icon-hammer',
    'fantasy-icon-gem',
    'fantasy-icon-eye',
    'fantasy-icon-dragon',
    'fantasy-icon-door',
    'fantasy-icon-dices',
    'fantasy-icon-d20',
    'fantasy-icon-food',
    'fantasy-icon-key',
    'fantasy-icon-full-helmet',
    'fantasy-icon-flail',
    'fantasy-icon-wand',
    'fantasy-icon-fireball',
    'fantasy-icon-map',
    'fantasy-icon-money',
    'fantasy-icon-cards',
    'fantasy-icon-poison',
    'fantasy-icon-tube',
    'fantasy-icon-book',
    'fantasy-icon-skull',
    'fantasy-icon-horned-skull',
    'fantasy-icon-sign',
    'fantasy-icon-shield',
    'fantasy-icon-emblem',
    'fantasy-icon-scroll',
    'fantasy-icon-notes',
    'fantasy-icon-potion',
    'fantasy-icon-flag',
    'fantasy-icon-sword',
    'fantasy-icon-ring',
    'fantasy-icon-torch',
    'fantasy-icon-chest',
    'fantasy-icon-tree',
    'fantasy-icon-goblet',
    'fantasy-icon-helmet',
    'fantasy-icon-hat',
    'fantasy-icon-scepter',
    'fantasy-icon-animal'
];