import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Input } from "semantic-ui-react";

const AutoSaveInput = (props) => {
    const [timer, setTimer] = useState(null);
    
    useEffect(() => {
        return () => timer && clearTimeout(timer);
    }, []);

    const onBlurInner = () => {
        timer && clearTimeout(timer);
        props?.onBlur();
    };

    const onChangeInner = (e) => {
        timer && clearTimeout(timer);
        props?.onChange(e);
        setTimer(setTimeout(onBlurInner, 2000));
    };

    let inputProps = {
        ...props,
        onChange: onChangeInner,
        onBlur: onBlurInner
    };

    if (props.textarea) {
        return <textarea {...inputProps}/>;
    }
    else {
        return <Input {...inputProps}/>;
    }
};

AutoSaveInput.propTypes = {
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    textarea: PropTypes.bool
};

export default AutoSaveInput;