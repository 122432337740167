import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Form, List } from "semantic-ui-react";

import { GROUPS_FORM_LINK } from "../../router/links";
import { apiService } from '../../utils/apiService';

const GroupsList = () => {
    const navigate = useNavigate();
    
    const [newGroupName, setNewGroupName] = useState('');
    const [canEdit, setCanEdit] = useState(false);
    const [groupsList, setGroupsList] = useState([]);
    const [inProgress, setInProgress] = useState(false);

    useEffect(() => {
        document.title = "Overdice";
    }, []);

    const getData = () => {
        setInProgress(true);
        apiService.get('/v1/groups/list')
            .then(result => {
                setInProgress(false);
                setCanEdit(result?.canEdit);
                setGroupsList((result?.entries) || []);
            })
            .catch(() => {
                setInProgress(false);
            });
    }
    useEffect(getData, []);

    const getGroupInfo = (group) => {
        let url = GROUPS_FORM_LINK.replace(':id', group.id);
        return (
            <List.Item key={'group-' + group.id}>
                <List.Icon name="group" size="large" verticalAlign="middle" />
                <List.Content>
                    <List.Header as={Link} to={url}>{group.name}</List.Header>
                    <List.Description>Автор: {group.gameMaster?.email}</List.Description>
                </List.Content>
            </List.Item>
        );
    };

    const createGroup = () => {
        setInProgress(true);
        apiService.post('/v1/groups/create', { name: newGroupName })
            .then(result => {
                setInProgress(false);
                if (result?.id) {
                    navigate(GROUPS_FORM_LINK.replace(':id', result.id));
                }
            })
            .catch(() => {
                setInProgress(false);
            });
    };

    return (
        <Container>
            {canEdit && (<Form>
                <Form.Group inline>
                    <Form.Input 
                        placeholder='Название новой группы'
                        value={newGroupName}
                        onChange={(e, {value}) => setNewGroupName(value)}
                        disabled={inProgress}
                    />
                    <Form.Button
                        onClick={createGroup}
                        disabled={inProgress}
                    >
                        Создать
                    </Form.Button>
                </Form.Group>
            </Form>)}
            <List>
                {groupsList?.map((v) => getGroupInfo(v))}
            </List>
        </Container>
    );
};

export default GroupsList;