import React from "react";
import PropTypes from "prop-types";
import NotesList from "../../components/notesList";

const AdventurePointNotesTab = ({
    adventureId,
    pointId,
    disabled
}) => {
    return (
        <NotesList
            adventureId={adventureId}
            pointId={pointId}
            disabled={disabled}
        />
    );
};

AdventurePointNotesTab.propTypes = {
    adventureId: PropTypes.string.isRequired,
    pointId: PropTypes.string,
    disabled: PropTypes.bool
};

export default AdventurePointNotesTab;