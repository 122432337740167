import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Input, TextArea } from "semantic-ui-react";

const FormInput = ({
    id,
    type,
    listEntries,
    value,
    fluid,
    transparent,
    disabled,
    error,
    style,
    rows,
    action,
    label,
    onChange
}) => {
    const listid = listEntries && (id + "-list");
    const datalist = listEntries && (
        <datalist id={listid}>
            {listEntries.map((v,i,a) => (<option value={v} key={v}>{v}</option>))}
        </datalist>
    );

    const [newValue, setNewValue] = useState(value || '');

    useEffect(() => {
        setNewValue(value || '');
    }, [value]);

    const saveValue = () => {
        if (value !== newValue) {
            onChange?.(newValue);
        }
    };

    if (type === "textArea") {
        return (
            <TextArea 
                style={style}
                id={id}
                rows={rows}
                value={newValue}
                onChange={(e, data) => setNewValue(data.value)}
                onBlur={saveValue}
                disabled={disabled}
                error={error}
            />
        );
    } else {
        return (
            <>
                <Input 
                    action={action}
                    label={label}
                    labelPosition={label && 'right'}
                    fluid={fluid}
                    style={style}
                    transparent={transparent}
                    id={id}
                    type={type} 
                    value={newValue}
                    list={listid}
                    onChange={(e, data) => setNewValue(data.value)}
                    onBlur={saveValue}
                    disabled={disabled}
                    error={error}
                />
                {datalist}
            </>
        );
    }
};

FormInput.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    listEntries: PropTypes.array,
    value: PropTypes.any,
    fluid: PropTypes.bool,
    transparent: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.any,
    style: PropTypes.any,
    rows: PropTypes.number,
    action: PropTypes.any,
    label: PropTypes.any,
    onChange: PropTypes.func.isRequired
};

export default FormInput;