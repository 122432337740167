import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation, Link } from "react-router-dom";
import { Button, Icon, Image, Menu, Dropdown } from "semantic-ui-react";

import { apiService } from '../../utils/apiService';

import {
    DASHBOARD_LINK,
    CHARACTER_FORM_LINK,
    CHARACTER_NEW_LINK,
    GROUPS_LIST_LINK,
    GROUPS_BASE_LINK,
    PROFILE_LINK,
    ROLE_MODELS_BASE_LINK,
    ROLE_MODELS_LIST_LINK,
    ADVENTURES_BASE_LINK,
    ADVENTURES_LIST_LINK
} from '../../router/links';

const AppHeader = ({ user, isEmpty, logout }) => {
    const fixedMenuStyle = {
      backgroundColor: '#fff',
      border: '1px solid #ddd',
      boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
    }

    let location = useLocation();

    const [characters, setCharacters] = useState([]);
    const [activeItem, setActiveItem] = useState(location.pathname);

    useEffect(() => {
        if (user?.canViewCharacters) {
            apiService.get('/v1/characters/list')
                .then(result => setCharacters(result));
        }
    }, [user]);

    useEffect(() => {
        setActiveItem(location.pathname);
    }, [location.pathname]);

    const charactersItems = characters?.map((c) => {
        let link = CHARACTER_FORM_LINK.replace(':id', c.id);
        return <Dropdown.Item as={Link} to={link} key={c.id}>{c.name}</Dropdown.Item>
    });

    return !(user?.email) && !isEmpty ? null : (
        <div className="app-menu-wrapper">
            <Menu borderless style={fixedMenuStyle} fixed="top">
                <Menu.Item header as={Link} to={DASHBOARD_LINK} target={isEmpty ? '__blank' : ''}>
                    <Image 
                        size='mini' 
                        src="data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABhWlDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw1AUhU9TiyIVByuIdMhQxcGCqIijVLEIFkpboVUHk5f+QZOGJMXFUXAtOPizWHVwcdbVwVUQBH9A3AUnRRcp8b6k0CLGC4/3cd49h/fuA4RGhalm1wSgapaRisfEbG5V7H6FD4MIYAxhiZl6Ir2YgWd93VM31V2UZ3n3/Vl9St5kgE8knmO6YRFvEM9sWjrnfeIQK0kK8TnxuEEXJH7kuuzyG+eiwwLPDBmZ1DxxiFgsdrDcwaxkqMTTxBFF1ShfyLqscN7irFZqrHVP/sJgXltJc51WGHEsIYEkRMiooYwKLERp10gxkaLzmId/2PEnySWTqwxGjgVUoUJy/OB/8Hu2ZmFq0k0KxoDAi21/jADdu0Czbtvfx7bdPAH8z8CV1vZXG8DsJ+n1thY5Avq3gYvrtibvAZc7wNCTLhmSI/lpCYUC8H5G35QDBm6B3jV3bq1znD4AGZrV8g1wcAiMFil73ePdPZ1z+7enNb8fhCByruFT+9gAAAAGYktHRAD/AP8A/6C9p5MAAAAJcEhZcwAALiMAAC4jAXilP3YAAAAHdElNRQfpARUFLTc5ZFP8AAAAGXRFWHRDb21tZW50AENyZWF0ZWQgd2l0aCBHSU1QV4EOFwAAA1ZJREFUSMfFVs9Lol0UfpqGFkYIFQhCE20Ciwjc9C6nRUFgtErkcwKDdpLSQhBq1/wB5l5eoZlS14m/2ggpVELiwghdNIpplK8SqBDYMxsVNXUa+Pi+B87ivffcc859zrnnvMBgfAGwDyAEIA+ADck31vYbOn+NGQA/2wz+SX42znwIegCVvzDelErj7EDsDjJiMBhoMBj+5Gh3UOQ9D5lMJgqCwIeHB+bzeQqCQJPJNMiJvhfn72gxm81MJpM0Go1MJBJsIpFI0Gg08vb2lmazuR9dHTnpSOjKygqj0Sjr9Trtdju9Xi+74fV6abfbWa/XGY1Gubq62ivxrVLs2BRFkbVajT6fjzabjf1gs9no8/lYq9UoimKvm3wZatTy96Y3URQxOTmJ4+NjeDweOJ1OyGSynkmrVqswGAzQarXY2trC8/Mztre321UOPgP42r4ik8mg0WiQSCQQDoehUqkwMjLS08Hr6ysUCgUWFxeh0Wjg8Xi6Vb6i/YWura0xFotRkiTq9Xq63W4GAoG+FAUCAbrdbur1ekqSxEKhwJOTEy4sLLS/eFChUDAUCrFWqzGdTtPv9zMYDLJarXJzc5Plcvmd8XK5TK1Wy2q1ymAwSL/fz3A4zFwuR0mSaLFYmk7Aq6srkuTNzQ1dLhd1Ol3LaCQSocPheOfA4XAwEom0nOl0OrpcLh4eHpIki8UiAfATgML8/DwAQKlU4uLiAmq1GnK5HAAgCALi8TgymUyL2Ewmg3g8DkEQAAByuRxqtRpnZ2dYXl4GAJAEgAIAhFwuVyuyUqnEXC7XEW0qlaLFYml9WywWplKpDp37+3tms1mS5NPTE3d2dtjouNgHwIODA97d3fHt7a1nQmOxGEulEkulEq+vr3vq5PN5np6ecnp6usn//lDjof1qXn99fR0bGxtQqVRQKpUYHR3F+Pg4hoeHWxTV63VIkoRKpYLHx0ek02mcn5/D6XR2l+n0UFur+Kd9Z2xsDEdHR5ibm0Mmk0EqlcLl5SVIYmlpCbOzs5iamkIymcTe3h5eXl66jZ+0N72ZfjPAarW2uI1EIq3KyWaztFqtg2bDDAAMdbXrH/16uSiKmJiYAAAUi8XultCNb+3N7sMD54Oy+7+OzP9k6P+rvy2/ATCsIuuFaK/aAAAAAElFTkSuQmCC" 
                        style={{ paddingRight: '1em' }} 
                    />
                    <span style={{ marginLeft: '-0.55em', fontSize: '1.3em', verticalAlign: 'middle' }}>
                        verdice
                    </span>
                </Menu.Item>
                {user?.canViewCharacters && (
                    <Dropdown item text='Персонажи'>
                        <Dropdown.Menu>
                            {charactersItems}
                            {charactersItems && charactersItems.length > 0 ? <Dropdown.Divider /> : null}
                            <Dropdown.Item as={Link} to={CHARACTER_NEW_LINK}>Создать нового</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )}
                {user?.canViewGroups && (
                    <Menu.Item as={Link} to={GROUPS_LIST_LINK} active={activeItem.includes(GROUPS_BASE_LINK)}>Группы</Menu.Item>
                )}
                {user?.canViewRoleModels && (
                    <Menu.Item as={Link} to={ROLE_MODELS_LIST_LINK} active={activeItem.includes(ROLE_MODELS_BASE_LINK)}>Ролевые модели</Menu.Item>
                )}
                {user?.canViewAdventures && (
                    <Menu.Item as={Link} to={ADVENTURES_LIST_LINK} active={activeItem.includes(ADVENTURES_BASE_LINK)}>Приключения</Menu.Item>
                )}
                {isEmpty ? null : (
                    <Menu.Item position="right">
                        <Link to={PROFILE_LINK}><b>{user.email}</b></Link>
                        <Button icon compact style={{ marginLeft: '1em' }} onClick={logout}>
                            <Icon name="log out" />
                        </Button>
                    </Menu.Item>
                )}
            </Menu>
        </div>
    );
};

AppHeader.propTypes = {
    user: PropTypes.object, 
    isEmpty: PropTypes.bool, 
    logout: PropTypes.func.isRequired
};

export default AppHeader;