import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Form, Modal } from "semantic-ui-react";

const CreateNamedEntryDialog = ({
    isOpen,
    title,
    errors,
    onCancel,
    onSave
}) => {
    const [name, setName] = useState('');

    useEffect(() => {
        setName('');
    }, [isOpen])

    return (
        <Modal size="tiny" open={isOpen}>
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field
                        error={errors?.['Name']}
                        title={errors?.['Name']}
                    >
                        <label className="required-label" htmlFor="new-entry-name-input">Название <span>*</span></label>
                        <input
                            id="new-entry-name-input"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => onCancel?.()}>
                    Отмена
                </Button>
                <Button primary onClick={() => onSave?.(name)}>
                    Сохранить
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

CreateNamedEntryDialog.propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    errors: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
};

export default CreateNamedEntryDialog;