import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { Link, useNavigate } from "react-router-dom";
import { Button, Container, Grid, Header, Icon, Item, ItemContent, ItemDescription, ItemGroup, ItemHeader, ItemImage } from "semantic-ui-react";
import { ADVENTURE_PAGE_LINK } from "../../router/links";
import ConfirmationDialog from "../../modals/confirmationDialog";
import { createAdventure, deleteAdventure, loadAdventuresList } from "../../api/adventures";
import CreateNamedEntryDialog from "../../modals/createNamedEntryDialog";

const AdventuresListPage = () => {
    const navigate = useNavigate();

    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isNewDialogOpen, setIsNewDialogOpen] = useState(false);
    const [newEntryErrors, setNewEntryErrors] = useState({});
    const [confirmationDialog, setConfirmationDialog] = useState({
        isOpen: false
    });

    useEffect(() => {
        document.title = "Overdice - приключения";
    }, []);

    const loadList = () => {
        setIsLoading(true);
        loadAdventuresList()
            .then(result => {
                setIsLoading(false);
                setList((result?.entries) || []);
            })
            .catch(() => setIsLoading(false));
    };

    useEffect(loadList, []);

    const onAddNewAdventure = (name) => {
        setIsLoading(true);
        setNewEntryErrors({});
        createAdventure({ name })
            .then((result) => {
                setIsNewDialogOpen(false);
                if (result?.id) {
                    navigate(ADVENTURE_PAGE_LINK.replace(':id', result.id));
                } else {
                    loadList();
                }
            })
            .catch((result) => {
                setIsLoading(false);
                setNewEntryErrors(result?.errors);
            });
    };

    const handleDelete = (id, name) => {
        setConfirmationDialog({
            message: `Вы действительно хотите удалить приключение '${name}'?`,
            isOpen: true,
            id
        });
    };

    const cancelDelete = () => {
        setConfirmationDialog({
            isOpen: false
        });
    };

    const confirmDelete = () => {
        const id = confirmationDialog.id;
        cancelDelete();
        setIsLoading(true);
        deleteAdventure(id)
            .then(loadList)
            .catch(() => setIsLoading(false));
    };

    const items = list.map((entry, ind) => (
        <Item key={'adventure-' + ind}>
            <ItemImage style={{ width: 30 }}>
                <Icon name="globe" size='large' />
            </ItemImage>
            <ItemContent>
                <ItemHeader>
                    <Link to={ADVENTURE_PAGE_LINK.replace(':id', entry.id)}>
                        {entry.name}
                    </Link>
                    <Icon 
                        className="remove-button"
                        name="trash alternate" 
                        title='Удаление приключения'
                        onClick={() => handleDelete(entry.id, entry.name)}
                    />
                </ItemHeader>
                <ItemDescription>
                    <span>Создан</span>
                    <b>
                        <Moment local format="DD.MM.YYYY hh:mm">
                            {entry.createdAt}
                        </Moment>
                    </b>
                    <span>Изменен</span>
                    <b>
                        <Moment local format="DD.MM.YYYY hh:mm">
                            {entry.updatedAt}
                        </Moment>
                    </b>
                </ItemDescription>
            </ItemContent>
        </Item>
    ));

    return (
        <Container>   
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Header size="medium" className="inline-title">
                            Приключения
                        </Header>
                        <Button
                            circular
                            icon='plus'
                            size="tiny"
                            className="inline-title"
                            disabled={isLoading}
                            title="Создание нового"
                            onClick={() => {
                                setIsNewDialogOpen(true);
                                setNewEntryErrors({});
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal'>
                    <Grid.Column>
                        <ItemGroup divided>{items}</ItemGroup>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <CreateNamedEntryDialog
                isOpen={isNewDialogOpen}
                title='Создание нового приключения'
                onCancel={() => setIsNewDialogOpen(false)}
                onSave={onAddNewAdventure}
                errors={newEntryErrors}
            />
            <ConfirmationDialog
                isOpen={confirmationDialog.isOpen}
                title='Удаление приключения'
                message={confirmationDialog.message}
                onCancel={cancelDelete}
                onSave={confirmDelete}
            />
        </Container>
    );
};

export default AdventuresListPage;