import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button, Dropdown, Form, FormField, FormGroup, Grid, Header, Icon, Item, ItemContent, ItemDescription, ItemExtra, ItemGroup, ItemHeader, ItemImage, List, ListItem, Pagination, Segment } from "semantic-ui-react";
import Markdown from 'react-markdown';
import { ADVENTURE_CHAPTER_PAGE_LINK, ADVENTURE_PERSON_PAGE_LINK, ADVENTURE_PLACE_PAGE_LINK, ADVENTURE_POINT_PAGE_LINK } from "../../../router/links";
import EditNoteDialog from "./modals/editNoteDialog";
import { loadAdventureChaptersList } from "../../../api/adventureChapters";
import { loadAdventurePersonsList } from "../../../api/adventurePersons";
import { createAdventureNote, deleteAdventureNote, loadAdventureNotesList, updateAdventureNote } from "../../../api/adventureNotes";
import ConfirmationDialog from "../../../modals/confirmationDialog";
import { loadAdventurePlaces } from "../../../api/adventurePlace";
import { loadAdventurePointsList } from "../../../api/adventurePoints";
import { escapeRegex } from "../../../utils/textUtils";
import { convertEntryToOption, getFantasyIconOptions } from "../../../utils/convertUtils.jsx";

const NotesList = ({
    adventureId,
    chapterId,
    personId,
    placeId,
    pointId,
    disabled,
    rowClassName
}) => {
    const [editDialog, setEditDialog] = useState({ isOpen: false });
    const [editNoteErrors, setEditNoteErrors] = useState({});
    const [confirmationDialog, setConfirmationDialog] = useState({ isOpen: false });
    const [chapterIdFilter, setChapterIdFilter] = useState(chapterId);
    const [personIdFilter, setPersonIdFilter] = useState(personId);
    const [placeIdFilter, setPlaceIdFilter] = useState(placeId);
    const [pointIdFilter, setPointIdFilter] = useState(pointId);
    const [iconFilter, setIconFilter] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [pagesCount, setPagesCount] = useState(1);
    const [notes, setNotes] = useState([]);
    const [chapters, setChapters] = useState([]);
    const [persons, setPersons] = useState([]);
    const [places, setPlaces] = useState([]);
    const [points, setPoints] = useState([]);

    const icons = getFantasyIconOptions();

    useEffect(() => setChapterIdFilter(chapterId), [chapterId]);
    useEffect(() => setPersonIdFilter(personId), [personId]);
    useEffect(() => setPlaceIdFilter(placeId), [placeId]);
    useEffect(() => setPointIdFilter(pointId), [pointId]);
    
    const reloadList = () => {
        if (adventureId) {
            setIsLoading(true);
            loadAdventureNotesList(adventureId, chapterIdFilter, personIdFilter, placeIdFilter, pointIdFilter, iconFilter, pageNumber)
                .then(result => {
                    setIsLoading(false);
                    if (result) {
                        setNotes(result.entries);
                        setPageNumber(result.pageNumber || 1);
                        setPagesCount(result.pagesCount || 1);
                    }
                })
                .catch(() => setIsLoading(false));
        }
    };

    const loadOptions = () => {
        if (adventureId) {
            loadAdventureChaptersList(adventureId).then(result => result && setChapters([{}].concat(result.entries.map(convertEntryToOption))));
            loadAdventurePersonsList(adventureId).then(result => result && setPersons([{}].concat(result.entries.map(convertEntryToOption))));
            loadAdventurePlaces(adventureId, null, null).then(result => setPlaces([{}].concat(result.map(convertEntryToOption))));
        }
    };

    useEffect(() => {
        if (adventureId) {
            loadAdventurePointsList(adventureId, chapterIdFilter, placeIdFilter, null, null).then(result => setPoints([{}].concat(result.entries.map(convertEntryToOption))));
        }
    }, [adventureId, chapterIdFilter, placeIdFilter])

    useEffect(reloadList, [adventureId, chapterIdFilter, personIdFilter, placeIdFilter, pointIdFilter, iconFilter, pageNumber]);
    useEffect(loadOptions, [adventureId]);

    const cancelEdit = () => setEditDialog({ isOpen: false });

    const confirmEdit = (data) => {
        setIsLoading(true);
        setEditNoteErrors({});
        (data.id ? updateAdventureNote(data.id, data) : createAdventureNote(data))
            .then(() => {
                cancelEdit();
                reloadList();
            })
            .catch((result) => {
                setIsLoading(false);
                setEditNoteErrors(result?.errors);
            });
    };

    const editNote = (entry) => {
        setEditDialog({ entry, isOpen: true });
        setEditNoteErrors({});
    }

    const createNote = () => editNote({
        adventure: { id: adventureId },
        chapter: chapterIdFilter ? { id: chapterIdFilter } : null,
        person: personIdFilter ? { id: personIdFilter } : null,
        place: placeIdFilter ? { id: placeIdFilter } : null,
        point: pointIdFilter ? { id: pointIdFilter } : null,
    });

    const handleDelete = (id) => {
        setConfirmationDialog({
            message: `Вы действительно хотите удалить заметку?`,
            isOpen: true,
            id
        });
    };

    const cancelDelete = () => {
        setConfirmationDialog({
            isOpen: false
        });
    };

    const confirmDelete = () => {
        const id = confirmationDialog.id;
        cancelDelete();
        setIsLoading(true);
        deleteAdventureNote(id)
            .then(reloadList)
            .catch(() => setIsLoading(false));
    };

    const getChapter = (entry) => 
        entry.chapter 
            ? (
                <ListItem 
                    icon='list ol'
                    content={
                    <Link to={ADVENTURE_CHAPTER_PAGE_LINK.replace(':id', entry.chapter.id)}>
                        {entry.chapter.name}
                    </Link>
                    }
                />
            ) 
            : null;

    const getPerson = (entry) => 
        entry.person 
            ? (
                <ListItem 
                    icon='group'
                    content={
                        <Link to={ADVENTURE_PERSON_PAGE_LINK.replace(':id', entry.person.id)}>
                            {entry.person.name}
                        </Link>
                    }
                />
            ) 
            : null;

    const getPoint = (entry) => 
        entry.point 
            ? (
                <ListItem 
                    icon='map signs'
                    content={
                        <Link to={ADVENTURE_POINT_PAGE_LINK.replace(':id', entry.point.id)}>
                            {entry.point.name}
                        </Link>
                    }
                />
            ) 
            : null;

    const getPlace = (entry) => 
        entry.place 
            ? (
                <ListItem 
                    icon='map'
                    content={
                        <Link to={ADVENTURE_PLACE_PAGE_LINK.replace(':id', entry.place.id)}>
                            {entry.place.name}
                        </Link>
                    }
                />
            ) 
            : null;

    const items = notes.map((entry, ind) => (
        <Item key={'note-' + ind}>
            <ItemImage style={{ width: 30 }}>
                <Icon name={entry.icon} size='big' />
            </ItemImage>
            <ItemContent>
                <ItemHeader>
                    <a onClick={() => editNote(entry)}>
                        {entry.name}
                    </a>
                    <Icon 
                        className="remove-button"
                        name="trash alternate" 
                        title='Удаление заметки'
                        onClick={() => handleDelete(entry.id)}
                    />
                </ItemHeader>
                <ItemDescription className="note-text-block">
                    <Markdown>{entry.text}</Markdown>
                </ItemDescription>
                <ItemExtra>
                    <List horizontal>
                        {getChapter(entry)}
                        {getPerson(entry)}
                        {getPoint(entry)}
                        {getPlace(entry)}
                    </List>
                </ItemExtra>
            </ItemContent>
        </Item>
    ));

    return (
        <>
            <Grid>
                <Grid.Row className={rowClassName}>
                    <Grid.Column>
                        <Header size="medium" className="inline-title">
                            Заметки
                        </Header>
                        <Button
                            circular
                            icon='plus'
                            size="tiny"
                            className="inline-title"
                            disabled={isLoading || disabled}
                            title="Создание новой"
                            onClick={createNote}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ paddingTop: '0px' }}>
                    <Grid.Column>
                        <Segment>
                            <Grid>
                                <Grid.Row style={{ paddingBottom: '0px' }}>
                                    <Grid.Column>
                                        <Form size="small">
                                            <FormGroup inline>
                                                <FormField width={2} title="Фильтр по иконке">
                                                    <Dropdown
                                                        selection
                                                        clearable
                                                        options={icons}
                                                        value={iconFilter}
                                                        search={(options, query) => {
                                                            const re = new RegExp(escapeRegex(query), 'i');
                                                            return options.filter((opt) => re.test(opt.searchText));
                                                        }}
                                                        disabled={disabled || isLoading}
                                                        onChange={(e, { value }) => setIconFilter(value)}
                                                        className="icon-dropdown"
                                                    />
                                                </FormField>
                                                <FormField width={4} title="Фильтр по главам">
                                                    <Icon name='list ol' size="large" />
                                                    <Dropdown 
                                                        search
                                                        selection
                                                        clearable
                                                        value={chapterIdFilter}
                                                        onChange={(e, { value }) => setChapterIdFilter(value)}
                                                        options={chapters}
                                                        disabled={disabled || isLoading || chapterId}
                                                    />
                                                </FormField>
                                                <FormField width={4} title="Фильтр по персонажам">
                                                    <Icon name='group' size="large" />
                                                    <Dropdown 
                                                        search
                                                        selection
                                                        clearable
                                                        value={personIdFilter}
                                                        onChange={(e, { value }) => setPersonIdFilter(value)}
                                                        options={persons}
                                                        disabled={disabled || isLoading || personId}
                                                    />
                                                </FormField>
                                                <FormField width={4} title="Фильтр по локациям">
                                                    <Icon name='map' size="large" />
                                                    <Dropdown 
                                                        search
                                                        selection
                                                        clearable
                                                        value={placeIdFilter}
                                                        onChange={(e, { value }) => setPlaceIdFilter(value)}
                                                        options={places}
                                                        disabled={disabled || isLoading || placeId}
                                                    />
                                                </FormField>
                                                <FormField width={4} title="Фильтр по местам">
                                                    <Icon name='map signs' size="large" />
                                                    <Dropdown 
                                                        search
                                                        selection
                                                        clearable
                                                        value={pointIdFilter}
                                                        onChange={(e, { value }) => setPointIdFilter(value)}
                                                        options={points}
                                                        disabled={disabled || isLoading || pointId}
                                                    />
                                                </FormField>
                                            </FormGroup>
                                        </Form>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <ItemGroup divided>{items}</ItemGroup>
                    </Grid.Column>
                </Grid.Row>
                {pagesCount > 1 ? (
                    <Grid.Row>
                        <Grid.Column>
                            <Pagination 
                                defaultActivePage={pageNumber} 
                                totalPages={pagesCount} 
                                secondary
                                onPageChange={(e, { activePage }) => setPageNumber(activePage)}
                            />
                        </Grid.Column>
                    </Grid.Row>
                ) : null}
            </Grid>
            <EditNoteDialog
                entry={editDialog.entry}
                chapters={chapters}
                persons={persons}
                points={points}
                isOpen={editDialog.isOpen}
                disabled={isLoading || disabled}
                onCancel={cancelEdit}
                onSave={confirmEdit}
                errors={editNoteErrors}
            />
            <ConfirmationDialog
                isOpen={confirmationDialog.isOpen}
                title='Удаление заметки'
                message={confirmationDialog.message}
                onCancel={cancelDelete}
                onSave={confirmDelete}
            />
        </>
    );
};

NotesList.propTypes = {
    adventureId: PropTypes.string.isRequired,
    chapterId: PropTypes.string,
    personId: PropTypes.string,
    placeId: PropTypes.string,
    pointId: PropTypes.string,
    disabled: PropTypes.bool,
    rowClassName: PropTypes.string
};

export default NotesList;