import React from "react";
import PropTypes from "prop-types";
import PointsList from "../../components/pointsList";

const AdventureChapterPointsTab = ({
    adventureId,
    chapterId,
    disabled
}) => {
    return (
        <PointsList
            adventureId={adventureId}
            chapterId={chapterId}
            disabled={disabled}
        />
    );
};

AdventureChapterPointsTab.propTypes = {
    adventureId: PropTypes.string.isRequired,
    chapterId: PropTypes.string,
    disabled: PropTypes.bool
};

export default AdventureChapterPointsTab;