import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Form, Grid, Message, Segment } from 'semantic-ui-react';

import { LOGIN_LINK, REGISTER_LINK } from '../../router/links';
import { apiService } from '../../utils/apiService';
import LoginLogo from '../header/loginLogo';

const SetPasswordPage = () => {
    const { id, token } = useParams();

    const [password, setPassword] = useState('');
    const [userData, setUserData] = useState({});
    const [inProgress, setInProgress] = useState(false);
    const [isComplete, setIsComplete] = useState(false);

    useEffect(() => {
        document.title = "Overdice";
    }, []);

    useEffect(
        () => {
            const data = {
                userId: id,
                resetToken: token
            };

            setInProgress(true);
            apiService.post('/v1/users/password/validate', data)
                .then(result => {
                    setInProgress(false);
                    setUserData(result);
                })
                .catch(() => {
                    setInProgress(false);
                });
        }, 
        [id, token]
    );

    const onSet = () => {
        const data = {
            userId: id,
            resetToken: token,
            newPassword: password
        };

        setInProgress(true);
        apiService.post('/v1/users/password/set', data)
            .then(() => {
                setInProgress(false);
                setIsComplete(true);
            })
            .catch(() => {
                setInProgress(false);
            });
    };

    return (
        <Grid textAlign='center' verticalAlign='middle' style={{ height: '100vh' }}>
            <Grid.Column style={{ maxWidth: 400 }}>
                <LoginLogo />
                {isComplete ? (
                    <Message>
                        Пароль успешно установлен, теперь вы можете <a href={LOGIN_LINK}>войти в систему</a>
                    </Message>
                ) : (
                    <>
                        {inProgress || (userData?.email) ? (
                            <>
                                <Message>
                                    Установка нового пароля для {userData.email}
                                </Message>
                                <Form size='large'>
                                    <Segment stacked>
                                        <Form.Input 
                                            fluid
                                            icon='lock'
                                            iconPosition='left'
                                            placeholder='Пароль'
                                            type='password'
                                            disabled={inProgress}
                                            onChange={(e, { value }) => setPassword(value)}
                                        />
                                        <Button fluid size='large' onClick={onSet} disabled={inProgress}>
                                            Сохранить
                                        </Button>
                                    </Segment>
                                </Form>
                            </>
                        ) : (
                            <Message>
                                Вы перешели по некорректной или устаревшей ссылке!
                            </Message>
                        )}
                        <Message>
                            <a href={LOGIN_LINK}>Войти в систему</a> / <a href={REGISTER_LINK}>Зарегистрироваться</a>
                        </Message>
                    </>
                )}
            </Grid.Column>
        </Grid>
    );
};

export default SetPasswordPage;