import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";

const ConfirmationDialog = ({
    isOpen,
    title,
    message,
    onCancel,
    onSave
}) => {
    return (
        <Modal size="tiny" open={isOpen}>
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
                {message}
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => onCancel?.()}>
                    Отмена
                </Button>
                <Button primary onClick={() => onSave?.()}>
                    Подтвердить
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

ConfirmationDialog.propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
};

export default ConfirmationDialog;