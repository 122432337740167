import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Container, Tab } from 'semantic-ui-react';
import { ADVENTURE_CHAPTERS_HASH, ADVENTURE_INFO_HASH, ADVENTURE_MATERIALS_HASH, ADVENTURE_NOTES_HASH, ADVENTURE_PERSONS_HASH, ADVENTURE_PLACES_HASH, ADVENTURE_POINTS_HASH, ADVENTURE_SEARCH_HASH } from '../../../router/links';
import AdventureInfoTab from '../tabs/adventure/adventureInfoTab';
import AdventureChaptersTab from '../tabs/adventure/adventureChaptersTab';
import AdventurePersonsTab from '../tabs/adventure/adventurePersonsTab';
import { loadAdventureData, updateAdventureField } from '../../../api/adventures';
import AdventurePlacesTab from '../tabs/adventure/adventurePlacesTab';
import { loadAdventurePlaces } from '../../../api/adventurePlace';
import AdventureNotesTab from '../tabs/adventure/adventureNotesTab';
import AdventureMaterialsTab from '../tabs/adventure/adventureMaterialsTab';
import AdventurePointsTab from '../tabs/adventure/adventurePointsTab';
import AdventureGlobalSearchTab from '../tabs/adventure/adventureGlobalSearchTab';
import AdventureHeader from '../components/adventureHeader';

const AdventurePage = () => {
    const { id } = useParams();
    const { hash } = useLocation();
    
    const [activeTab, setActiveTab] = useState(0);
    const [adventure, setAdventure] = useState({});
    const [places, setPlaces] = useState([]);
    const [name, setName] = useState('');
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isPlacesLoading, setIsPlacesLoading] = useState(false);

    const reloadPlaces = () => {
        if (id) {
            setIsPlacesLoading(true);
            loadAdventurePlaces(id)
                .then(result => {
                    setIsPlacesLoading(false);
                    setPlaces(result);
                })
                .catch(() => setIsPlacesLoading(false));
        }
    };

    const getData = () => {
        setIsDataLoading(true);
        loadAdventureData(id)
            .then(result => {
                setIsDataLoading(false);

                setAdventure(result);
                setName(result.name);
                
                document.title = `Overdice - ${result.name || 'приключения'}`;

                reloadPlaces();
            })
            .catch(() => {
                setIsDataLoading(false);
            });
    }
    useEffect(getData, [id]);

    const saveField = (fieldName, value) => {
        setIsDataLoading(true);
        updateAdventureField(id, fieldName, value)
            .then(() => setIsDataLoading(false))
            .catch(() => setIsDataLoading(false));
    };

    const panes = [
        {
            tag: ADVENTURE_INFO_HASH,
            render: () => (
                <AdventureInfoTab 
                    key={'adventure-info-' + (adventure.id || 'none')}
                    disabled={isDataLoading}
                    adventure={adventure} 
                    onSaveField={saveField}
                />
            )
        },
        {
            tag: ADVENTURE_CHAPTERS_HASH,
            render: () => (
                <AdventureChaptersTab
                    key={'adventure-chapters-' + (adventure.id || 'none')}
                    disabled={isDataLoading}
                    adventureId={id}
                />
            )
        },
        {
            tag: ADVENTURE_PERSONS_HASH,
            render: () => (
                <AdventurePersonsTab
                    key={'adventure-persons-' + (adventure.id || 'none')}
                    disabled={isDataLoading}
                    adventureId={id}
                />
            )
        },
        {
            tag: ADVENTURE_PLACES_HASH,
            render: () => (
                <AdventurePlacesTab
                    key={'adventure-places-' + (adventure.id || 'none')}
                    disabled={isDataLoading || isPlacesLoading}
                    adventureId={id}
                    chapterId={null}
                    places={places}
                    onReload={reloadPlaces}
                />
            )
        },
        {
            tag: ADVENTURE_POINTS_HASH,
            render: () => (
                <AdventurePointsTab
                    key={'adventure-points-' + (adventure.id || 'none')}
                    disabled={isDataLoading}
                    adventureId={id} 
                />
            )
        },
        {
            tag: ADVENTURE_NOTES_HASH,
            render: () => (
                <AdventureNotesTab
                    key={'adventure-notes-' + (adventure.id || 'none')}
                    disabled={isDataLoading}
                    adventureId={id} 
                />
            )
        },
        {
            tag: ADVENTURE_MATERIALS_HASH,
            render: () => (
                <AdventureMaterialsTab
                    key={'adventure-materials-' + (adventure.id || 'none')}
                    disabled={isDataLoading}
                    adventureId={id}
                />
            )
        },
        {
            tag: ADVENTURE_SEARCH_HASH,
            render: () => (
                <AdventureGlobalSearchTab
                    key={'adventure-search-' + (adventure.id || 'none')}
                    disabled={isDataLoading}
                    adventureId={id}
                />
            )
        }
    ];

    const syncActiveTab = () => {
        if (hash) {
            panes.forEach((tab, index) => {
                if (tab.tag === hash) {
                    setActiveTab(index);
                }
            });
        } else {
            setActiveTab(0);
        }
    };
    useEffect(syncActiveTab, [hash]);

    const getMenu = () => <></>;

    return (
        <Container>
            <AdventureHeader
                adventureId={id}
                adventureName={name}
                adventurePageTag={panes[activeTab].tag}
            />
            <Tab menu={{ as: getMenu }} panes={panes} activeIndex={activeTab} />
        </Container>
    );
};

export default AdventurePage;